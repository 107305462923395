import { VoteApiProvider } from '@telescope/cassini-hooks';
import { CassiniProvider, Container, Spinner, LightMode } from '@telescope/cassini-ui';
import { AuthProvider, TailorProvider, UserRole } from 'features';
import { DataContext, StorageProvider, useWidget, UseWidgetResponse, VoteHistoryProvider } from 'providers';
import { Suspense, useContext, useEffect } from 'react';
import { customTheme } from 'themes';
import { getTextDirection, getWidgetId, VERSION_CHECK } from 'utils';
import TagManager from 'react-gtm-module';

const wid = getWidgetId();

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    data: { ruleset_collection_id, tailor_access_key, topic_id, gtm_container_id, ...settings },
  } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.settings,
  });

  const { language } = useContext(DataContext);
  const direction = getTextDirection(language);

  // Initialize Google Tag Manager
  useEffect(() => {
    TagManager.initialize({
      gtmId: gtm_container_id as string,
    });
  }, [gtm_container_id]);

  const userRole = window.USER_ROLE || UserRole.AUDIENCE;

  return (
    <StorageProvider prefix={`${wid}:`}>
      <VoteHistoryProvider>
        <CassiniProvider theme={customTheme({ direction })}>
          <Suspense
            fallback={
              <Container centerContent>
                <Spinner />
              </Container>
            }
          >
            <VoteApiProvider
              settings={{
                apiKey: settings[`apiKey_${userRole}`],
                versionId: `${settings[`version_id_${userRole}`]}${VERSION_CHECK[userRole]}`,
              }}
              options={{ authType: 2 }}
            >
              <AuthProvider role={userRole}>
                <TailorProvider
                  rulesetCollectionId={ruleset_collection_id}
                  tailorId={tailor_access_key}
                  topicId={topic_id}
                  env="prod"
                >
                  <LightMode>{children}</LightMode>
                </TailorProvider>
              </AuthProvider>
            </VoteApiProvider>
          </Suspense>
        </CassiniProvider>
      </VoteHistoryProvider>
    </StorageProvider>
  );
};
