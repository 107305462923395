import { Link } from '@telescope/cassini-ui';
import { useContext } from 'react';
import { DataContext } from 'providers';
import { GenericObject } from 'types';
import { NavLink as NavLinkRouter, useLocation } from 'react-router-dom';

export const NavLink = ({ data }: { data: GenericObject }) => {
  const { language } = useContext(DataContext);

  const isInternalRoute = data.link.startsWith('/');

  const location = useLocation();
  const isActive = location.pathname.startsWith(data.link);

  if (!isInternalRoute) {
    return (
      <Link
        href={data.link}
        target="_blank"
        aria-label={data.aria_label}
        fontFamily="Lato, sans-serif"
        fontWeight="700"
        fontSize={'14px'}
        color="secondary.400"
        _hover={{ color: 'white', textDecoration: 'none' }}
      >
        {data[`text_${language}`]}
      </Link>
    );
  }

  return (
    <Link
      as={NavLinkRouter}
      to={data.link}
      key={data.name}
      aria-label={data.aria_label}
      fontFamily="Lato, sans-serif"
      fontWeight="700"
      fontSize={'14px'}
      color={isActive ? 'secondary.600' : 'secondary.400'}
      _activeLink={{ color: 'secondary.600' }}
      _hover={{ color: 'white', textDecoration: 'none' }}
      isExternal
    >
      {data[`text_${language}`]}
    </Link>
  );
};
