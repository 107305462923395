import { useContext } from 'react';
import { useAuth } from 'features/auth';
import { Button, Show, Text } from '@telescope/cassini-ui';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { ExitIcon } from 'components';

export const AuthNav = () => {
  const { logout, isAuthenticated } = useAuth();
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.login });
  const { language } = useContext(DataContext);

  const handleLogout = async () => {
    await logout();
  };

  if (!isAuthenticated()) {
    return null;
  }

  return (
    <Button
      onClick={handleLogout}
      variant="link"
      color="secondary.700"
      fill="secondary.700"
      _hover={{ bg: '#141519', color: '#fff' }}
      _active={{ bg: '#141519', color: 'secondary.600', fill: 'secondary.600' }}
      w={{ base: '70px', md: 'auto' }}
      alignSelf={{ base: 'stretch', md: 'center' }}
      minWidth={['auto', 'auto']}
      p="12px"
      pr={{ base: '6px', md: '12px' }}
      fontSize="12px"
      fontWeight={900}
    >
      <ExitIcon fill="inherit" />

      <Show above="md">
        <Text className="User__logout-btn" ml={2}>
          {data.logout_cta[`logout_${language}`]}
        </Text>
      </Show>
    </Button>
  );
};
