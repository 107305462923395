import { Stack, Box, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@telescope/cassini-ui';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export interface AuthModalProps {
  children: React.ReactNode;
}

export const AuthModal = (props: AuthModalProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Modal isOpen={true} onClose={() => navigate(location.state?.backgroundLocation || '..')} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="lg" />
        <ModalBody
          bgImage={{
            base: 'https://ts-cms-production.votenow.tv/campaign/10/11/1011537/1670522931639228333df796.81035235.jpg',
            lg: 'https://ts-cms-production.votenow.tv/campaign/10/11/1011537/16705229256392282d22b700.68003620.jpg',
          }}
          bgSize="cover"
          bgRepeat="no-repeat"
          color={'on-surface'}
        >
          <Box data-vote-option-name={''}>
            <Stack alignItems="center" spacing={3} mb={5}>
              {props.children}
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
