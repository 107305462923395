import { Box, Button, Stack, Heading, Text, VStack, HStack } from '@telescope/cassini-ui';
import { isTruthy } from '@telescope/cassini-utilities';
import { Share, useAuth, UserRole } from 'features';
import { DataContext, useWidget, UseWidgetResponse, useVoteHistory } from 'providers';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { slugify } from 'utils';
import { useVotingGrid } from '../providers';
import { SocialGraphic } from '.';
import { replaceWildcardsWithProperties } from 'features';

export const Thanks = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.thank_you });
  const { language } = useContext(DataContext);
  const { role } = useAuth();

  const { voteHistory } = useVoteHistory();
  const { activeVoteOption, activeCategory, nextCategory, allowedCategories } = useVotingGrid();
  const nextCategorySlug = slugify(nextCategory.name_en);

  // Check if vote history length is >= than the allowed categories length. If so, display social graphic
  // We're not getting into the votes per language in this case as all languages have 22 categories.
  const isLastAllowedCategory = Object.keys(voteHistory.history).length >= Object.keys(allowedCategories).length;

  const navigate = useNavigate();

  const handleTwitterVote = () => {
    let copy = (data.twitter_vote.tweet[`copy_${language}`] || '')
      .replace(/{{CATEGORY}}/gi, activeCategory[`name_${language}`] || '')
      .replace(/{{CATEGORY_HASHTAG}}/gi, activeCategory[`hashtag_${language}`] || '');
    copy = replaceWildcardsWithProperties(copy, activeVoteOption).trim();

    const url = new URL('https://twitter.com/intent/tweet');
    url.searchParams.append('text', copy);
    if (data.twitter_vote.tweet.link) url.searchParams.append('url', data.twitter_vote.tweet.link);

    window.open(url, '_blank', 'height=420,width=550');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <VStack textAlign="center" spacing={10} pb={5}>
      <VStack textAlign="center" spacing={3}>
        <Text color="secondary.500" fontWeight="500" fontSize="sm">
          {activeCategory[`name_${language}`]}
        </Text>
        <Heading fontSize={['xl', '2xl']}>{data[`${role}_headline`][`${role}_headline_${language}`]}</Heading>
        <Text fontSize={['sm', 'md']}>{data[`${role}_description`][`${role}_description_${language}`]}</Text>
      </VStack>

      {isTruthy(activeCategory.display_twitter_vote) && role === UserRole.AUDIENCE && (
        <Box w="100%">
          <Text mb={2}>{data.twitter_vote.cta[`cta_${language}`]}</Text>
          <Button
            aria-label="Vote on Twitter"
            variant="secondary"
            size="sm"
            w={['100%', 'auto']}
            onClick={handleTwitterVote}
          >
            {data.twitter_vote.button[`button_${language}`]}
          </Button>
        </Box>
      )}

      {role === UserRole.AUDIENCE && <Share category={activeCategory} voteOption={activeVoteOption} />}

      <Stack direction={['column-reverse', 'row']} w="100%" justifyContent="center" spacing={[3, 6]}>
        <Button
          aria-label="Close Modal"
          variant="primary"
          size="sm"
          border="2px solid #FFFFFF"
          onClick={() => navigate('..')}
        >
          {data.close_button_text[`button_text_${language}`]}
        </Button>
        <Button
          aria-label="Open Next Category"
          variant="secondary"
          size="sm"
          onClick={() => navigate(`/${nextCategorySlug}`)}
        >
          {data.navigate_button_text[`button_text_${language}`]}
        </Button>
      </Stack>

      {role === UserRole.AUDIENCE && isLastAllowedCategory && (
        <Box>
          <SocialGraphic
            displayButton={isTruthy(data.settings.display_social_graphic_download_button)}
            displayCta={isTruthy(data.settings.display_social_graphic_cta)}
            buttonCopy={data.social_graphic_download_button[`social_graphic_download_button_${language}`]}
            ctaCopy={data.social_graphic_cta[`social_graphic_cta_${language}`]}
          />
        </Box>
      )}
    </VStack>
  );
};
