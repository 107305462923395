import { Box, Container, Flex } from '@telescope/cassini-ui';
import { Footer, Navigation, Header } from 'features';
import { Outlet } from 'react-router-dom';

export const AppShell = () => {
  return (
    <Flex direction="column" minH="100vh" bg="transparent" margin="0 auto" alignItems={'center'}>
      <Header />
      <Navigation />
      <Box
        as="main"
        flex="1"
        p={[0, 3, 5, 12]}
        w={'100%'}
        bgImage="url(https://ts-cms-production.votenow.tv/campaign/10/11/1011537/167052835263923d601c2025.11401451.png), url(https://ts-cms-production.votenow.tv/campaign/10/11/1011537/1670527159639238b7e5ca44.12651473.jpg)"
        bgSize="100% auto, cover"
        bgRepeat="no-repeat"
        bgPosition="center bottom 7%, center top"
        bgAttachment="scroll, fixed"
      >
        <Container maxW={'1250px'} margin="0 auto">
          <Outlet />
        </Container>
      </Box>
      <Footer />
    </Flex>
  );
};
