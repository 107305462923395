import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
  Link,
  Flex,
  Spacer,
  Show,
  Hide,
} from '@telescope/cassini-ui';
import { HamburgerIcon } from 'components';
import { useContext } from 'react';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { LogoIconLarge, LogoIconSmall } from 'components';
import { AuthNav } from 'features';

export const HeaderMenu = () => {
  const { language } = useContext(DataContext);
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.header });

  return (
    <Flex w="100%" h="60px" bg="#23252B" fontFamily="Lato, sans-serif" pr={{ base: 0, md: '10px' }} gap="18px">
      <Menu>
        <MenuButton
          as={IconButton}
          w="60px"
          h="60px"
          minW="auto"
          bg="transparent"
          _active={{ bg: '#141519', fill: 'secondary.600' }}
          _hover={{ bg: '#141519' }}
          textAlign="left"
          fill="#fff"
          icon={<HamburgerIcon fill="inherit" />}
        ></MenuButton>

        <MenuList bg="#141519" w="100vw" border="none" borderRadius={0} m={0} mt={-2}>
          {data.links.map((link: Record<string, any>) => {
            return (
              <MenuItem bg="transparent" key={link.url} as={Box} p={0}>
                <Link
                  isExternal
                  href={link.url}
                  color="secondary.700"
                  _hover={{ color: '#fff', bg: '#23252B', textDecoration: 'none' }}
                  _focusVisible={{
                    color: '#fff',
                    bg: '#23252B',
                    boxShadow: 'none',
                    outline: '.25rem solid #a0a0a0',
                    outlineOffset: '-0.25rem',
                  }}
                  fontSize="16px"
                  fontWeight={500}
                  p="12px"
                  w="100%"
                >
                  {link.copy[`copy_${language}`]}
                </Link>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>

      <Link isExternal href={data.logo.link} data-group alignSelf="center">
        <Show above="md">
          <LogoIconLarge fill="secondary.600" _groupHover={{ fill: '#fff' }} />
        </Show>

        <Hide above="md">
          <LogoIconSmall fill="secondary.600" _groupHover={{ fill: '#fff' }} />
        </Hide>
      </Link>

      <Spacer />

      <AuthNav />
    </Flex>
  );
};
