export const WindowStatus = {
  OPEN: '1',
  CLOSED: '0',
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type WindowStatus = typeof WindowStatus[keyof typeof WindowStatus];

export const ErrorType = {
  GENERIC: 'generic',
  OVERLIMIT: 'overlimit',
  WINDOW: 'window',
  LANGUAGE: 'language',
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type ErrorType = typeof ErrorType[keyof typeof ErrorType];

export type VoteOption = {
  image: string;
  name: string;
  headline: string;
  description: string;
};
