import { useVote, VoteAPIResponseCode } from '@telescope/cassini-hooks';
import { Box, Button, Flex, Heading, IconButton, Image, Link, Spacer, Text, VStack } from '@telescope/cassini-ui';
import { isTruthy } from '@telescope/cassini-utilities';
import { useAuth } from 'features';
import parse from 'html-react-parser';
import { DataContext, useVoteHistory, useWidget, UseWidgetResponse } from 'providers';
import { useContext, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorType } from 'types';
import { ROUTES } from 'utils';
import { useVotingGrid } from '../providers';

interface VoteOptionDetailProps {
  // TODO: fix type
  voteOption?: any;
  /**
   * Callback fired when previous arrow is clicked
   */
  onPrevious(): void;
  /**
   * Callback fired when next arrow is clicked
   */
  onNext(): void;
  /**
   * Callback fired when vote call is successful
   */
  onVoteSuccess: () => void;
}

export const VoteOptionDetail = ({ onVoteSuccess, onPrevious, onNext }: VoteOptionDetailProps) => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.vote_modal });
  const { language } = useContext(DataContext);
  const { voteHistory, updateVoteHistory } = useVoteHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { activeCategory, activeVoteOption: voteOption } = useVotingGrid();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const vote = useVote();

  const categoryVotedOption = voteHistory.history[activeCategory.id];
  const voted = categoryVotedOption === voteOption.id;

  const displayNavigation = isTruthy(data.settings.display_navigation);

  if (!voteOption) {
    return null;
  }

  const handleVote = async () => {
    if (!user) {
      navigate(ROUTES.AUTH, { state: { backgroundLocation: location } });
      return;
    }

    let error;

    const payload = {
      category: voteOption.catId,
      contestant: voteOption.id,
      user_id: user?.user.user_id,
      method: user?.user.method,
    };

    try {
      setIsSubmitting(true);
      const { response_code, votestring } = await vote({ ...payload, action_type: 'vote' });

      if (response_code === VoteAPIResponseCode.VALID) {
        votestring && updateVoteHistory(JSON.parse(votestring));
        return onVoteSuccess();
      }

      const errors = {
        [VoteAPIResponseCode.WINDOW]: ErrorType.WINDOW,
        [VoteAPIResponseCode.OVERLIMIT]: ErrorType.OVERLIMIT,
        [VoteAPIResponseCode.GENERAL_INVALID]: ErrorType.GENERIC,
      };

      // @ts-expect-error: res.response_code might not be a property of errors
      // In this case use the known property VoteAPIResponseCode.GENERAL_INVALID
      error = errors[response_code] || errors[VoteAPIResponseCode.GENERAL_INVALID];

      navigate(ROUTES.ERROR, { state: { errorType: error } });
    } catch {
      navigate(ROUTES.ERROR, { state: { errorType: ErrorType.GENERIC } });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {displayNavigation && (
        <>
          <IconButton
            fontSize="3xl"
            aria-label="Previous"
            icon={<FaChevronLeft />}
            variant="link"
            top={'50%'}
            position="absolute"
            left={{ base: 0, lg: '-50px' }}
            onClick={onPrevious}
          />
          <IconButton
            fontSize="3xl"
            aria-label="Next"
            icon={<FaChevronRight />}
            variant="link"
            top={'50%'}
            position="absolute"
            right={{ base: 0, lg: '-50px' }}
            onClick={onNext}
          />{' '}
        </>
      )}

      <Flex
        direction={{ base: 'column-reverse', lg: 'row' }}
        gap={{ base: 5, lg: 16 }}
        alignItems={{ base: 'center', lg: 'revert' }}
        textAlign={{ base: 'center', lg: 'left' }}
      >
        <Flex className="VoteOptionDetail__details-container" direction="column" w="100%">
          <VStack
            m="auto"
            mb={20}
            spacing={3}
            w={{ base: '85%', md: '100%' }}
            textAlign={{ base: 'center', lg: 'left' }}
            align={{ base: 'center', lg: 'flex-start' }}
          >
            <Text className="VoteOptionDetail__category-name" color="secondary.500" size="small" fontWeight={500}>
              {parse(activeCategory[`name_${language}`])}
            </Text>

            <Heading className="VoteOptionDetail__heading" as="h1" size="medium" textAlign="initial">
              {parse(voteOption[`name_${language}`])}
            </Heading>

            <Text
              className="VoteOptionDetail__secondary-name"
              size="small"
              fontWeight={500}
              _empty={{ display: 'none' }}
            >
              {parse(voteOption[`additional_name_${language}`])}
            </Text>

            <Text size="small" fontWeight={'500'}>
              {parse(voteOption[`studio_name_${language}`])}
            </Text>

            {voteOption[`description_${language}`] && (
              <Text size="medium" _empty={{ display: 'none' }}>
                {parse(voteOption[`description_${language}`])}
              </Text>
            )}

            {voteOption[`copyright_${language}`] && (
              <Text size="extraSmall" color="secondary.700">
                {parse(voteOption[`copyright_${language}`])}
              </Text>
            )}

            {data.watch_button_text[`watch_button_text_${language}`] && data.where_to_watch_urls[language] && (
              <Link
                href={data.where_to_watch_urls[language]}
                key={activeCategory.id}
                target="_blank"
                color="primary.500"
                display="inline-flex"
                alignItems="center"
                fontWeight={700}
              >
                {data.watch_button_text[`watch_button_text_${language}`]}
                {language === 'ar' ? <TiChevronLeft /> : <TiChevronRight />}
              </Link>
            )}
          </VStack>

          <Spacer />

          <Button
            className="VoteOptionDetail__vote-button"
            variant={categoryVotedOption ? 'tertiary' : 'secondary'}
            size={{ base: 'md', lg: 'sm' }}
            w={{ base: '100%', md: 'auto' }}
            maxW={{ base: '335px' }}
            mb={[2, 3]}
            mx={{ base: 'auto', lg: 0 }}
            alignSelf={{ base: 'stretch', lg: 'flex-start' }}
            isDisabled={!!categoryVotedOption}
            onClick={() => handleVote()}
            isLoading={isSubmitting}
          >
            {categoryVotedOption
              ? parse(
                  voted
                    ? data.voted_button_text[`voted_button_text_${language}`]
                    : parse(data.vote_button_text[`vote_button_text_${language}`]),
                )
              : parse(data.vote_button_text[`vote_button_text_${language}`])}
          </Button>
        </Flex>

        <Box className="VoteOptionDetail__img-container" w="100%" maxW="470px">
          <Image
            crossOrigin="anonymous"
            className="VoteOptionDetail__img"
            src={voteOption.image}
            m="auto"
            w={{ base: '85%', md: '100%' }}
          />
        </Box>
      </Flex>
      <Text
        color="secondary.400"
        sx={{ a: { color: 'primary.500' } }}
        textAlign={{ base: 'center', lg: 'left' }}
        size="extraSmall"
        maxW="400px"
        mx={{ base: 'auto', lg: 0 }}
      >
        {parse(data.disclaimer[`disclaimer_${language}`])}
      </Text>
    </>
  );
};
