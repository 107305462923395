import { useDisclosure } from '@chakra-ui/react';
import { Box, CloseButton, Flex, Grid, GridItem, Heading, IconButton, Text, Tooltip } from '@telescope/cassini-ui';
import { ClosedOptionCard, VideoPlayer } from 'features';
import parse from 'html-react-parser';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { useContext, useEffect, useRef } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { ErrorType } from 'types';
import { ROUTES } from 'utils';
import { useClosed } from '../providers';

export const ClosedOptionsGrid = () => {
  const { isOpen: isTooltipOpen, onOpen, onToggle, onClose } = useDisclosure();
  const pageRef = useRef<HTMLDivElement>(null);
  const { language } = useContext(DataContext);
  const { data: grid } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.grid });

  const { categorySlug } = useParams();
  const { categories, isCategoryAllowed } = useClosed();

  const navigate = useNavigate();

  const category = categories[categorySlug!];
  const isAllowed = isCategoryAllowed(category);

  useEffect(() => {
    if (!pageRef?.current) return;
    pageRef.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'start' });
  }, [categorySlug, pageRef?.current]);

  if (category && !isAllowed) {
    return <Navigate to={ROUTES.ERROR} state={{ errorType: ErrorType.LANGUAGE }} />;
  }

  if (!category) {
    return <Navigate to=".." />;
  }

  return (
    <Box p={['0px', '0px', '13px']} py={'5px'} ref={pageRef} key={categorySlug}>
      <Box as="section" w="100%" bg="black" p={[3, 5, 8]} borderRadius={{ base: 2, md: 5 }}>
        <Flex justify="flex-end">
          <CloseButton size="lg" onClick={() => navigate('..')} />
        </Flex>
        <Box textAlign="center">
          <Box
            aria-live="polite"
            aria-atomic="true"
            role="region"
            pb={[3, 5]}
            display="inline-flex"
            alignItems="center"
          >
            <Heading size={'small'} mr={2}>
              {parse(category[`name_${language}`])}
            </Heading>

            {category[`description_${language}`] && (
              <Tooltip
                label={parse(category[`description_${language}`])}
                bg="#4A4E58"
                placement="top"
                isOpen={isTooltipOpen}
              >
                <IconButton
                  variant="unstyled"
                  minW="none"
                  minHeight="auto"
                  height="auto"
                  aria-label="description"
                  onMouseEnter={onOpen}
                  onMouseLeave={onClose}
                  onClick={onToggle}
                  icon={<AiOutlineInfoCircle />}
                />
              </Tooltip>
            )}
          </Box>

          {category[`video_url_${language}`] && (
            <VideoPlayer title={category[`name_${language}`]} src={category[`video_url_${language}`]} />
          )}
          {grid.additional_info[`additional_info_${language}`] && (
            <Box>
              {grid.additional_info[`additional_info_${language}`] && (
                <Text as="p">{parse(grid.additional_info[`additional_info_${language}`])}</Text>
              )}
            </Box>
          )}
        </Box>

        <Grid templateColumns={{ base: '1fr', md: 'repeat(2, minmax(0, 1fr))' }} gap={[4, 4, 10]} justifyItems="center">
          {Object.values(category.voteOptions).map((option: any) => (
            <GridItem key={option.id} w="100%" minW={0}>
              <ClosedOptionCard option={option} />
            </GridItem>
          ))}
        </Grid>

        <Outlet />
      </Box>
    </Box>
  );
};
