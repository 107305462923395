import { WID } from 'utils/constants';

export const getWidgetId = () => {
  const search = new URLSearchParams(window.location.search);
  const widgetId = search.get('wid');

  return widgetId || WID;
};

export const getSidString = () => {
  const search = new URLSearchParams(window.location.search);
  const sid = search.get('sid');
  const sidString = sid ? `/snapshot/${sid}` : '';

  return sidString;
};
