import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, Button, Box, Text } from '@telescope/cassini-ui';
import { useLocation } from 'react-router-dom';
import { IoChevronDownSharp, IoCloseSharp } from 'react-icons/io5';
import { useContext, useMemo } from 'react';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { GenericObject } from 'types';
import { AuthNav } from 'features/auth';
import { NavLink } from './NavLink';

export const NavMenu = () => {
  const { language } = useContext(DataContext);
  const { data: links } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.navigation });

  const location = useLocation();
  const activeLink = useMemo(
    () => links.find((link: GenericObject) => location.pathname.startsWith(link.link)),
    [location.pathname],
  );

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={isOpen ? <IoCloseSharp /> : <IoChevronDownSharp />}
            bg="#000"
            w="100%"
            minH="60px"
            _active={{ bg: '#000' }}
            _hover={{ bg: '#000' }}
            textAlign="left"
            pl={3}
          >
            <Text color="secondary.600" ml={0} p={0}>
              {activeLink[`text_${language}`]}
            </Text>
          </MenuButton>
          <MenuList bg="#000" w="100vw" border="none" borderRadius={0} mt={-2}>
            {(links as GenericObject[])
              .filter(link => link.link !== activeLink.link)
              .map(link => {
                return (
                  <MenuItem bg="transparent" key={link.link}>
                    <NavLink data={link} />
                  </MenuItem>
                );
              })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
