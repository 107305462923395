import { createIcon } from '@chakra-ui/icon';

export const FacebookShareIcon = createIcon({
  displayName: 'FacebookShareIcon',
  viewBox: '0 0 25 25',
  path: (
    <>
      <defs>
        <clipPath id="a">
          <path d="M375 0v1740H0V0h375Z" />
        </clipPath>
        <clipPath id="b">
          <path d="M12.499 0C19.4 0 24.998 5.597 24.998 12.499c.002 6.237-4.57 11.409-10.544 12.346v-8.734h2.912l.555-3.612h-3.467v-2.345c0-.99.485-1.952 2.037-1.952h1.575V5.127s-1.43-.245-2.798-.245c-2.857 0-4.722 1.73-4.722 4.862v2.755H7.372v3.612h3.174v8.734C4.57 23.908 0 18.738 0 12.499 0 5.597 5.597 0 12.499 0Z" />
        </clipPath>
        <clipPath id="c">
          <path d="M7.897 0c.932 0 1.893.114 2.41.186l.183.027.204.032V3.32H9.119c-1.552 0-2.037.965-2.037 1.952v2.345h3.467l-.555 3.612H7.079v8.734a12.547 12.547 0 0 1-3.904 0v-8.734H0V7.617h3.175V4.862C3.175 1.73 5.042 0 7.897 0Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="translate(-192 -543)">
        <g clipPath="url(#b)" transform="translate(192 543)">
          <path fill="#1877F2" d="M0 0h25v24.845H0V0z" />
        </g>
        <g clipPath="url(#c)" transform="translate(199.37 547.882)">
          <path fill="#FFF" d="M0 0h10.694v20.115H0V0z" />
        </g>
      </g>
    </>
  ),
});

export const TwitterShareIcon = createIcon({
  displayName: 'TwitterShareIcon',
  viewBox: '0 0 26 26',
  path: (
    <>
      <defs>
        <clipPath id="x">
          <path d="M375 0v1740H0V0h375Z" />
        </clipPath>
        <clipPath id="y">
          <path d="M12.585 0c6.951 0 12.586 5.635 12.586 12.585 0 6.951-5.635 12.586-12.586 12.586C5.635 25.17 0 19.536 0 12.585 0 5.635 5.635 0 12.585 0Z" />
        </clipPath>
        <clipPath id="z">
          <path d="M10.197 0c.874 0 1.656.354 2.208.928a6.122 6.122 0 0 0 1.92-.713 2.96 2.96 0 0 1-1.33 1.63 6.255 6.255 0 0 0 1.739-.465 6.156 6.156 0 0 1-1.513 1.527c.007.122.007.25.007.379 0 3.889-3.043 8.378-8.606 8.378A8.599 8.599 0 0 1 0 10.334 6.195 6.195 0 0 0 4.481 9.12C3.16 9.094 2.04 8.243 1.66 7.071a3.044 3.044 0 0 0 1.365-.049C1.639 6.747.597 5.563.597 4.133v-.036c.407.22.87.354 1.368.366a2.904 2.904 0 0 1-1.35-2.448c0-.537.15-1.045.406-1.478a8.663 8.663 0 0 0 6.236 3.078 2.57 2.57 0 0 1-.083-.671C7.174 1.319 8.529 0 10.197 0Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#x)" transform="translate(-157 -542)">
        <g clipPath="url(#y)" transform="translate(157 542.608)">
          <path fill="#4198D3" d="M0 0h25.171v25.171H0V0z" />
        </g>
        <g clipPath="url(#z)" transform="translate(162.525 549.975)">
          <path fill="#FFF" d="M0 0h14.734v11.664H0V0z" />
        </g>
      </g>
    </>
  ),
});
