import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  SystemStyleObject,
  ThemingProps
} from '@chakra-ui/system';

export interface TimeStatProps extends HTMLChakraProps<'div'>, ThemingProps<'TimeStat'> {}

export const TimeStat = forwardRef<TimeStatProps, 'div'>((props, ref) => {
    
  const timeStatStyles: SystemStyleObject = {
    position: 'relative',
    flex: '1 1 0%',
    padding: '4px',
    margin: '5px'
  };

  const { className, children, ...rest } = omitThemingProps(props);

  return (
      <chakra.div ref={ref} {...rest} __css={timeStatStyles}>
        <dl>{children}</dl>
      </chakra.div>
  );
});

TimeStat.displayName = 'TimeStat';
