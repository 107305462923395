import { extendTheme, withDefaultColorScheme } from '@telescope/cassini-ui';
import { buttonTheme, dividerTheme, headingTheme, textTheme, modalTheme } from './components';
import { breakpoints } from 'utils';

export const customTheme = ({ direction = 'ltr' }: { direction: string }) => {
  return extendTheme(
    {
      direction,
      config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
      },
      colors: {
        primary: {
          400: '#EC9D88',
          500: '#E87953',
          600: '#CF6C4A',
        },
        secondary: {
          400: '#A0A0A0',
          500: '#FBAB5E',
          600: '#F47521',
          700: '#DADADA',
        },
      },
      components: {
        Button: buttonTheme,
        Divider: dividerTheme,
        Heading: headingTheme,
        Text: textTheme,
        Modal: modalTheme,
      },
      styles: {
        global: {
          body: {
            color: '#FFF',
            fontFamily: 'Aeonik, sans-serif',
          },
        },
      },
      semanticTokens: {
        colors: {
          surface: '#000',
          'on-surface': '#FFF',
          footer: 'linear-gradient(#000000, #192E38)',
          'on-footer': '#A0A0A0',
          subtle: '#888',
        },
      },
      breakpoints: breakpoints,
    },
    withDefaultColorScheme({ colorScheme: 'primary', components: ['Checkbox'] }),
  );
};
