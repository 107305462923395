import { ComponentStyleConfig } from '@chakra-ui/react';

export const headingTheme: ComponentStyleConfig = {
  baseStyle: {
    textAlign: 'center',
  },
  sizes: {
    extraSmall: { fontSize: 'lg' },
    small: { fontSize: 'xl' },
    medium: { fontSize: '2xl' },
    large: { fontSize: '3xl' },
    extraLarge: { fontSize: '4xl' },
  },
};
