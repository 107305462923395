import { UserRole } from 'features/auth/types';

export const ACTION_TYPES = {
  GET: 'get',
  VOTE: 'vote',
  LOGIN: 'login',
  REGISTER: 'register',
};

export const ROUTES = {
  AUTH: '/auth',
  HOME: '/',
  ERROR: '/error',
};

export const MODAL_TYPES = {
  CONFIRMATION: 'confirmation',
  ERROR_GENERIC: 'errorGeneric',
  ERROR_OVERLIMIT: 'errorOverlimit',
  ERROR_WINDOW: 'errorWindow',
};

export const NAVIGATE = {
  NEXT: 'next',
  PREV: 'prev',
  ALL: 'all',
};

export const WIDGET_STATE_OPTIONS = {
  apiUrl: 'https://widgetstate.votenow.tv/v1/state/',
  pollingRate: 15000,
};

export const WID = '61004131a553c7e5';
export const CAT_WID = '010091d125832745';
export const CONTESTANTS_WID = 'f170514195e3c76e';
export const STYLES_WID = '317091c10553c731';
export const VERSION_CHECK = {
  [UserRole.AUDIENCE]: '7LQw2fw6e6kks6XKdwor',
  [UserRole.JUDGE]: 'amZub7xAtsNTW7KTM8ns',
};

export const SKIP_HASH = '08b43519a7e36ee4c6c5615d1663bcfd';

export const URL_REGEX = /[^0-9A-Za-z$\-_.+!*'{()},]/g;
export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const KEYS = {
  DOWN: 40,
  ESCAPE: 27,
  LEFT: 37,
  RIGHT: 39,
  TAB: 9,
  UP: 38,
};
