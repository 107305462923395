import { Flex, Text, Link, HStack, Spacer } from '@telescope/cassini-ui';
import { useContext } from 'react';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { LogoIconLarge } from 'components';
import { AuthNav } from 'features';

export const HeaderBanner = () => {
  const { language } = useContext(DataContext);
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.header });

  return (
    <HStack h="60px" w="100%" bg="#23252b" p="0 18px" align="center" spacing="34px" fontFamily="Lato, sans-serif">
      <Link isExternal href={data.logo.link} data-group>
        <LogoIconLarge fill="#f47521" _groupHover={{ fill: '#fff' }} />
      </Link>

      <Flex h="100%" align="stretch">
        {data.links.map((link: Record<string, any>) => (
          <Flex
            as={Link}
            isExternal
            href={link.url}
            key={link.url}
            color="secondary.700"
            _hover={{ color: '#fff', bg: '#141519', textDecoration: 'none' }}
            _focusVisible={{
              color: '#fff',
              bg: '#141519',
              boxShadow: 'none',
              outline: '.25rem solid #a0a0a0',
              outlineOffset: '-0.25rem',
            }}
            fontSize="16px"
            fontWeight={500}
            px="14px"
            align="center"
          >
            <Text as="span"> {link.copy[`copy_${language}`]} </Text>
          </Flex>
        ))}
      </Flex>

      <Spacer />

      <AuthNav />
    </HStack>
  );
};
