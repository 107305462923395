import React, { useCallback, useMemo, useContext, createContext } from 'react';
import { DataContext, useStorage, useVoteHistory } from 'providers';
import { useMutation, useQuery, useQueryClient, UseMutateAsyncFunction } from '@tanstack/react-query';
import { useVote, UseVoteAPIResponseDTO, VoteAPIResponseCode } from '@telescope/cassini-hooks';
import { GenericObject } from '@telescope/cassini-utilities';
import { AuthType, AUTH_LOCALSTORAGE_KEY } from '../utils/constants';
import { UserRole } from '../types';
import TagManager from 'react-gtm-module';

// TODO: Replace 'any' with correct typings for auth function payloads
export interface AuthProviderConfig<User = unknown, Error = unknown> {
  key?: string;
  waitInitial?: boolean;
  LoaderComponent?: () => JSX.Element;
  ErrorComponent?: ({ error }: { error: Error | null }) => JSX.Element;
}

export interface AuthContextValue<User = unknown, Error = unknown> {
  user: User | undefined;
  login: UseMutateAsyncFunction<User, any, GenericObject>;
  register: UseMutateAsyncFunction<User, any, GenericObject>;
  logout: UseMutateAsyncFunction<any, any, void, any>;
  isAuthenticated: () => boolean;
  role: UserRole;
}

export interface AuthProviderProps {
  role: UserRole;
  children: React.ReactNode;
}

export function initReactQueryAuth<User = unknown, Error = unknown>(config: AuthProviderConfig<User, Error>) {
  const AuthContext = createContext<AuthContextValue<User, Error> | null>(null);

  const {
    key = 'auth-user',
    waitInitial = true,
    LoaderComponent = () => <div>Loading...</div>,
    ErrorComponent = (error: any) => <div style={{ color: 'tomato' }}>{JSON.stringify(error, null, 2)}</div>,
  } = config;

  function AuthProvider({ role = UserRole.AUDIENCE, children }: AuthProviderProps): JSX.Element {
    const storage = useStorage();
    const loginTelescope = useVote();
    const queryClient = useQueryClient();
    const { language } = useContext(DataContext);

    const { updateVoteHistory, resetVoteHistory } = useVoteHistory();

    const loadUser = () => {
      return storage.getItem(`${AUTH_LOCALSTORAGE_KEY}_${role}`);
    };

    const logoutFn = async () => {
      resetVoteHistory();
      return storage.removeItem(`${AUTH_LOCALSTORAGE_KEY}_${role}`);
    };

    const loginFn = async (payload: GenericObject) => {
      const { email, code, ...rest } = payload;
      const data = { user_id: email ?? code, ...rest };

      const { response_code, votestring } = (await loginTelescope({
        ...data,
        action_type: AuthType.LOGIN,
      })) as UseVoteAPIResponseDTO;

      votestring && updateVoteHistory(JSON.parse(votestring));

      // Users that have submitted before will get an overlimit response.
      // For this app, they should be allowed to login when over limit.
      const isValidLogin =
        response_code === VoteAPIResponseCode.VALID || response_code === VoteAPIResponseCode.OVERLIMIT;

      if (!isValidLogin) {
        return Promise.reject(response_code);
      }

      email && TagManager.dataLayer({ dataLayer: { event: 'AudienceLogin', user_id: email, language } });
      TagManager.dataLayer({ dataLayer: { event: 'Login', role: role } });
      return { user: data } as User;
    };

    const registerFn = async (payload: GenericObject) => {
      const { email, ...rest } = payload;
      const data = { user_id: email, ...rest };

      return { user: data, isRegistered: true } as User;
    };

    const {
      data: user,
      error,
      status,
      isLoading,
      isSuccess,
    } = useQuery<User, Error>({
      queryKey: [key],
      queryFn: loadUser,
    });

    const isAuthenticated = useCallback(() => {
      // TODO: fix User type and remove 'any' typings here
      return user && (user as any).user;
    }, [user]);

    const setUser = useCallback(
      (data: User) => {
        queryClient.setQueryData([key], data);
        storage.setItem(`${AUTH_LOCALSTORAGE_KEY}_${role}`, data);
        return data;
      },
      [queryClient, storage, role],
    );

    const loginMutation = useMutation({
      mutationFn: loginFn,
      onSuccess: user => {
        setUser(user);
      },
    });

    const registerMutation = useMutation({
      mutationFn: registerFn,
      onSuccess: user => {
        setUser(user);
      },
    });

    const logoutMutation = useMutation({
      mutationFn: logoutFn,
      onSuccess: () => {
        queryClient.resetQueries([key]);
      },
    });

    const value = useMemo(
      () => ({
        user,
        login: loginMutation.mutateAsync,
        register: registerMutation.mutateAsync,
        logout: logoutMutation.mutateAsync,
        isLoggingIn: loginMutation.isLoading,
        isRegistering: registerMutation.isLoading,
        isAuthenticated,
        role,
      }),
      [
        user,
        loginMutation.mutateAsync,
        registerMutation.mutateAsync,
        logoutMutation.mutateAsync,
        isAuthenticated,
        role,
      ],
    );

    if (isSuccess || !waitInitial) {
      return <AuthContext.Provider value={value as any}>{children}</AuthContext.Provider>;
    }

    if (isLoading) {
      return <LoaderComponent />;
    }

    if (error) {
      return <ErrorComponent error={error} />;
    }

    return <div>Unhandled status: {status}</div>;
  }

  function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
      throw new Error(`useAuth must be used within an AuthProvider`);
    }
    return context;
  }

  return { AuthProvider, AuthConsumer: AuthContext.Consumer, useAuth };
}
