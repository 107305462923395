import {
  Box,
  Button,
  Heading,
  Flex,
  Spacer,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@telescope/cassini-ui';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsExclamationCircle } from 'react-icons/bs';
import { ErrorType } from 'types';
import { ROUTES } from 'utils';

interface ErrorProps {
  errorType?: ErrorType;
}

export const Error = ({ errorType }: ErrorProps) => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.error_messages });
  const { language } = useContext(DataContext);

  const navigate = useNavigate();
  const location = useLocation();
  const error = errorType || location.state.errorType;

  const getErrorMessage = useCallback(() => {
    const headline = error
      ? data[error].headline[`headline_${language}`]
      : data.generic.headline[`headline_${language}`];

    const description = error ? data[error].description[`description_${language}`] : '';

    const buttonText = error
      ? data[error].button_text[`button_text_${language}`]
      : data.generic.button_text[`button_text_${language}`];

    return { headline, description, buttonText };
  }, [data, error, language]);

  const message = getErrorMessage();

  return (
    <Modal isOpen={true} onClose={() => navigate('..', { replace: true })}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="lg" />
        <ModalBody
          bgImage={{
            base: 'https://ts-cms-production.votenow.tv/campaign/10/11/1011537/1670522950639228467bbc95.68708260.jpg',
            lg: 'https://ts-cms-production.votenow.tv/campaign/10/11/1011537/16705229466392284281b247.07720795.jpg',
          }}
          bgSize="cover"
          bgRepeat="no-repeat"
          color="on-surface"
        >
          <Box mt={'80px'} mb={10}>
            <Flex direction="column" align="center">
              <Box mb={10} textAlign="center" sx={{ svg: { display: 'inline-block' } }}>
                <BsExclamationCircle size={30} />
                <Heading size="medium" color="white" mb={2}>
                  {message.headline}
                </Heading>
                {message.description && <Text size="medium">{message.description}</Text>}
              </Box>
              <Spacer />
              <Box>
                <Button
                  onClick={() => navigate(ROUTES.HOME, { replace: true })}
                  variant="secondary"
                  className="NavigationButton"
                  marginTop={{ base: 3, md: 8 }}
                >
                  {message.buttonText}
                </Button>
              </Box>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
