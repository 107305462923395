// constants
const FLEX_TOPIC_ID = '';
export const JOB_POLLING_FREQUENCY = 1000;
export const ERROR_CODES = {
  CANCELED_BY_USER: '9000',
  INVALID_IMAGE_FILE: '11030',
  IMAGE_ASPECT_RATIO_NOT_SUPPORTED: '11035',
  IMAGE_WIDTH_MAX: '11040',
  IMAGE_WIDTH_MIN: '11045',
  IMAGE_HEIGHT_MAX: '11050',
  IMAGE_HEIGHT_MIN: '11055',
  IMAGE_FILESIZE_MAX: '11060',
  IMAGE_FILESIZE_MIN: '11065',
  MEDIA_EXTENSION_NOT_SUPPORTED: '11070',
};

export const generateTailorConfig = (topicId?: string, opts = {}) => {
  const now = Date.now();
  const defaultOptions = {
    actions: [
      {
        inputs: [
          {
            reference: '1',
            type: 'action_result',
          },
        ],
        output_details: [
          {
            format: 'jpeg',
            identifier: '3',
            keyframe: {
              type: 'percent',
              value: 25,
            },
            size: {
              type: 'scale_width',
              width: 467,
            },
          },
        ],
        type: 'thumbnail',
      },
      {
        overrides: [
          {
            inputs: [
              {
                reference: '2',
                type: 'action_result',
              },
            ],
            name: 'image',
          },
        ],
        type: 'flex',
      },
    ],
    flex: {
      id: now.toString(),
      custom_parameters: '',
      user_name: '-',
      message: '-',
      ruleset_collection_ids: '0',
      timestamp: now,
      topics: topicId || FLEX_TOPIC_ID,
    },
  };

  return { ...defaultOptions, ...opts };
};

export enum TailorJobStatus {
  SettingUp = 'Setting Up',
  ReadyForProcessing = 'Ready for Processing',
  Processing = 'Processing',
  Error = 'Error',
  Finished = 'Finished',
}
