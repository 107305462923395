import { Box, Flex, Heading, Image, Text, VStack } from '@telescope/cassini-ui';
import { useVotingGrid } from 'features/voting-app';
import parse from 'html-react-parser';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { useContext, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { splitAt } from 'lodash-contrib';
import { useStateList } from 'hooks';
import { CategoryButton } from './CategoryButton';
import { EqualHeightProvider } from '../providers/EqualHeightProvider';
import { ICategory } from 'types';
import { useAuth } from 'features';

export const CategoriesGrid = () => {
  const { language } = useContext(DataContext);
  const { data: copy } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.categories });
  const { categories, activeCategory, specialCategories } = useVotingGrid();

  const { categorySlug } = useParams();

  const categoriesSlugs = Object.keys(categories);
  const { currentIndex, setState } = useStateList(categoriesSlugs, { initialState: categorySlug });
  const categoriesGroups = splitAt(categoriesSlugs, activeCategory ? currentIndex : categoriesSlugs.length);

  const { role } = useAuth();

  useEffect(() => {
    if (!categorySlug) return;
    setState(categorySlug);
  }, [categorySlug]);

  return (
    <EqualHeightProvider>
      <>
        <VStack>
          <Box as="section" w="100%">
            <Box pb={[3, 5]}>
              <Box className="CategoriesGrid__img-container" mb={6} mt={{ base: 6, lg: 0 }}>
                <Image src={copy.image.url} alt={copy.image.alt} m="0 auto" />
              </Box>

              {copy[`${role}_headline`][`${role}_headline_${language}`] && (
                <Heading fontSize="34px">{parse(copy[`${role}_headline`][`${role}_headline_${language}`])}</Heading>
              )}

              {copy[`${role}_description`][`${role}_description_${language}`] && (
                <Text textAlign="center" size="medium">
                  {parse(copy[`${role}_description`][`${role}_description_${language}`])}
                </Text>
              )}
            </Box>

            <Flex w="100%" flexWrap="wrap-reverse" justify={'center'} direction={'row-reverse'}>
              {categoriesGroups[0]
                .map((slug: string) => <CategoryButton category={categories[slug]} key={slug} />)
                .reverse()}
            </Flex>

            <Outlet key={currentIndex} />

            <Flex w="100%" flexWrap="wrap" justify={'center'}>
              {categoriesGroups[1].map((slug: string) => (
                <CategoryButton category={categories[slug]} key={slug} />
              ))}
            </Flex>
          </Box>
        </VStack>
        <Box pb={[5, 7]} mt={'80px'}>
          {copy.special_headline[`special_headline_${language}`] && (
            <Heading size="extraLarge">{parse(copy.special_headline[`special_headline_${language}`])}</Heading>
          )}
          {copy.special_description[`special_description_${language}`] && (
            <Text textAlign="center" size="medium">
              {parse(copy.special_description[`special_description_${language}`])}
            </Text>
          )}
        </Box>

        <Flex w="100%" flexWrap="wrap" justify={'center'} mb={'80px'}>
          {specialCategories.map((category: ICategory) => (
            <CategoryButton category={category} key={category.name_en} />
          ))}
        </Flex>
      </>
    </EqualHeightProvider>
  );
};
