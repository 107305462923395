import { AspectRatio } from '@telescope/cassini-ui';

interface VideoProps {
  title: string;
  src: string;
}

export const VideoPlayer = (props: VideoProps) => {
  return (
    <AspectRatio maxW="680px" ratio={5 / 3} m="0 auto" mb={5}>
      <iframe
        title={props.title}
        src={props.src}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </AspectRatio>
  );
};
