import { Outlet, Route, Routes } from 'react-router-dom';
import { ClosedGrid, ClosedModal, ClosedOptionsGrid, Error } from 'features';
import { ROUTES } from 'utils';
import { ClosedProvider } from '../providers';

export const CLOSED_ROUTES = {
  OPTION: ':optionSlug',
  GRID: ':categorySlug',
};

export const ClosedRoutes = () => {
  return (
    <ClosedProvider>
      <Outlet />
      <Routes>
        <Route path="/" element={<ClosedGrid />}>
          <Route path={CLOSED_ROUTES.GRID} element={<ClosedOptionsGrid />}>
            <Route path={CLOSED_ROUTES.OPTION} element={<ClosedModal />} />
          </Route>
          <Route path={ROUTES.ERROR} element={<Error />} />
        </Route>
      </Routes>
    </ClosedProvider>
  );
};
