import { AppShell } from 'components';
import { useRoutes } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import { ContentRoutes } from './ContentRoutes';
import { GlobalModalRoutes } from './GlobalModalRoutes';

export const AppRoutes = () => {
  const element = useRoutes([
    {
      path: ROUTES.HOME,
      element: <AppShell />,
      children: [
        {
          path: '/',
          element: (
            <>
              <GlobalModalRoutes />
              <ContentRoutes />
            </>
          ),
        },
        {
          path: '*',
          element: (
            <>
              <GlobalModalRoutes />
              <ContentRoutes />
            </>
          ),
        },
      ],
    },
  ]);

  return <>{element}</>;
};
