import { Button, Checkbox, FormControl, Heading, Input, Text, VStack } from '@telescope/cassini-ui';
import { useAuth } from 'features/auth';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { FormEvent, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import parse from 'html-react-parser';
import { EMAIL_REGEX, ROUTES } from 'utils';
import { useNavigate } from 'react-router-dom';
import { ErrorType } from 'types';
import { VoteAPIResponseCode } from '@telescope/cassini-hooks';

type AudienceLoginFormValues = {
  email: string;
  optin_1: boolean;
  optin_2: boolean;
};

export const AudienceLogin = () => {
  // TODO: separate CMS section user and judge login
  // TODO: custom login fields in CMS
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.login });

  const { language } = useContext(DataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm<AudienceLoginFormValues>();

  const renderCheckboxes = () => {
    return data.optins.map((optin: any) => {
      if (!optin || !optin.name) return null;

      const label = optin.copy[`copy_${language}`] || '';
      const isRequired = optin.required === 'true';
      const errorMessage = isRequired ? optin.error_message[`error_message_${language}`] : false;
      const name = optin.name as keyof AudienceLoginFormValues;

      return (
        <Controller
          name={optin.name}
          key={optin.name}
          control={control}
          rules={{ required: errorMessage }}
          defaultValue={false}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <>
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                  checked={value}
                  ref={ref}
                  sx={{ '.chakra-checkbox__control': { borderColor: 'secondary.400', backgroundColor: '#192E38' } }}
                >
                  <Text sx={{ a: { color: 'primary.500' } }} color="secondary.700">
                    {parse(label)}
                  </Text>
                </Checkbox>
                {errors[name] && (
                  <Text color="red" size="small">
                    {errors[name]?.message}
                  </Text>
                )}
              </>
            );
          }}
        />
      );
    });
  };

  const { login } = useAuth();

  const submitLogin = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(async (formData: AudienceLoginFormValues) => {
      try {
        setIsSubmitting(true);
        formData.email = formData.email.trim();
        const user = await login({ ...formData, method: 'email' });

        if (user) {
          setIsSubmitting(false);
          navigate(-1);
        }
      } catch (error) {
        setIsSubmitting(false);
        const errors = {
          [VoteAPIResponseCode.WINDOW]: ErrorType.WINDOW,
          [VoteAPIResponseCode.GENERAL_INVALID]: ErrorType.GENERIC,
        };
        // @ts-expect-error: res.response_code might not be a property of errors
        const err = errors[error] || errors[VoteAPIResponseCode.GENERAL_INVALID];
        navigate(ROUTES.ERROR, { state: { errorType: err } });
      }
    })();
  };

  return (
    <form id="loginForm" onSubmit={submitLogin}>
      <VStack spacing={7} p={['40px 16px', 10]} maxW="700px">
        <Heading size="medium">{data.audience_headline[`audience_headline_${language}`]}</Heading>
        <FormControl w={'full'} sx={{ width: 'initial', marginTop: '10px !important' }}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: data.audience_error_message[`audience_error_message_${language}`],
              pattern: {
                value: new RegExp(EMAIL_REGEX, 'i'),
                message: data.audience_error_message[`audience_error_message_${language}`],
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <Input
                    w="full"
                    name="email"
                    placeholder={data.audience_placeholder[`audience_placeholder_${language}`]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value.toLowerCase())}
                    value={value}
                    maxLength={40}
                    variant="flushed"
                    textAlign="left"
                    fontWeight="900"
                    textTransform="uppercase"
                    letterSpacing="2px"
                    display="block"
                    mb={errors.email ? 1 : 6}
                    mx="auto"
                    maxW="450px"
                    _placeholder={{
                      fontWeight: 400,
                      textTransform: 'initial',
                      letterSpacing: 'normal',
                      color: '#FFFFFF',
                    }}
                  />
                  {errors.email && (
                    <Text color="red" size="small" mb={6}>
                      {errors.email.message}
                    </Text>
                  )}
                </>
              );
            }}
          />
          <VStack spacing={4} alignItems="left">
            {renderCheckboxes()}
          </VStack>
        </FormControl>
        <Button
          variant="secondary"
          size={['sm', 'md']}
          px={14}
          isLoading={isSubmitting}
          form="loginForm"
          type="submit"
          disabled={!isDirty || isSubmitting}
        >
          {data.button_text[`button_text_${language}`]}
        </Button>
      </VStack>
    </form>
  );
};
