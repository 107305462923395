import { ClosedRoutes, VotingAppRoutes } from 'features';
import { useWidget, UseWidgetResponse } from 'providers';
import { WindowStatus } from 'types';
import { useRoutes, useLocation } from 'react-router-dom';

export const ContentRoutes = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot });
  const location = useLocation();
  const backgroundLocation = location.state?.backgroundLocation;

  const isWindowOpen = data.settings.window_status === WindowStatus.OPEN;

  const closedSnapshotRoutes = [
    {
      path: '*',
      element: <ClosedRoutes />,
    },
  ];

  const openSnapshotRoutes = [
    {
      path: '*',
      element: <VotingAppRoutes />,
    },
  ];

  const routes = isWindowOpen ? openSnapshotRoutes : closedSnapshotRoutes;

  const element = useRoutes([...routes], backgroundLocation || location);

  return <>{element}</>;
};
