import React, { useMemo, useContext, createContext } from 'react';
import _, { keyBy } from 'lodash-es';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { CAT_WID, CONTESTANTS_WID, slugify, WIDGET_STATE_OPTIONS } from 'utils';
import { VOTING_ROUTES } from '../routes';
import { useMatch } from 'react-router-dom';
import { isTruthy } from '@telescope/cassini-utilities';
import { ICategory, IVoteOption } from 'types';

export interface VotingGridContextProps {
  categories: { [catId: string]: ICategory };
  activeCategory: ICategory;
  allowedCategories: ICategory[];
  nextCategory: ICategory;
  activeVoteOption: IVoteOption;
  specialCategories: any[];
  isCategoryAllowed: (category: ICategory) => boolean;
}

export interface VotingGridProviderProps {
  children: React.ReactNode;
}

const VotingGridContext = createContext<VotingGridContextProps | null>(null);

export function VotingGridProvider({ children }: VotingGridProviderProps): JSX.Element {
  const { data: categories } = useWidget({
    widgetStateOptions: { ...WIDGET_STATE_OPTIONS, apiHash: CAT_WID },
    select: (data: UseWidgetResponse) => keyBy(data.snapshot.data, 'id'),
  });

  const { language, languageCode } = useContext(DataContext);

  const { data: contestants } = useWidget({
    widgetStateOptions: { ...WIDGET_STATE_OPTIONS, apiHash: CONTESTANTS_WID },
    select: (data: UseWidgetResponse) => data.snapshot.data,
  });

  // TODO: improve logic that gets category and vote option slugs
  // Provider needs to have access to params from useParams
  // React Router v6 does not accept optional parameters on useMatch. We need to get slugs separately
  const { categorySlug = '' } = useMatch(`${VOTING_ROUTES.VOTING_GRID}*`)?.params || {};
  const { voteOptionSlug = '' } = useMatch(`${VOTING_ROUTES.VOTING_GRID}/${VOTING_ROUTES.VOTE_OPTION}`)?.params || {};

  const value = useMemo(() => {
    return _.chain(contestants)
      .groupBy(x => x.catId)
      .map((value, key) => {
        value.sort((a, b) => a[`name_${language}`].localeCompare(b[`name_${language}`], languageCode));
        const voteOptions = keyBy(value, contestant => slugify(contestant.name));
        return { voteOptions, ...categories[key] };
      })
      .filter(cat => cat.name_en && !isTruthy(cat.special))
      .keyBy(cat => slugify(cat.name_en))
      .value();
  }, [categories, contestants, language, languageCode]);

  const activeCategory = useMemo(() => value[categorySlug], [categorySlug, value]);

  const allowedCategories = useMemo(
    () => Object.values(value).filter((cat: ICategory) => cat.languages.split(', ').includes(language)),
    [value],
  );

  const activeCategoryIndex = activeCategory && allowedCategories.findIndex(el => el.id === activeCategory.id);

  const nextCategory = useMemo(() => {
    const index = (activeCategoryIndex + 1) % allowedCategories.length;
    return allowedCategories[index];
  }, [value, activeCategoryIndex, allowedCategories]);

  const activeVoteOption = useMemo(() => activeCategory?.voteOptions[voteOptionSlug], [activeCategory, voteOptionSlug]);
  // TODO: Figure out how to correctly type this
  const specialCategories = useMemo(
    () => Object.values(categories).filter((cat: any) => isTruthy(cat.special)),
    [categories],
  );

  const isCategoryAllowed = (category: ICategory) => {
    const allowedLanguages = category?.languages?.split(',').map((lang: string) => lang.trim());
    return allowedLanguages?.includes(language);
  };

  return (
    <VotingGridContext.Provider
      value={{
        categories: value,
        activeCategory,
        allowedCategories,
        nextCategory,
        activeVoteOption,
        specialCategories,
        isCategoryAllowed,
      }}
    >
      {children}
    </VotingGridContext.Provider>
  );
}

export function useVotingGrid() {
  const ctx = useContext(VotingGridContext);

  if (!ctx) {
    throw new Error(
      'useVotingGrid hook was called outside of context, wrap your component with VotingGridProvider component',
    );
  }

  return ctx;
}
