import { Box, Button, Flex, Image, Text, Heading, Spacer, Link, HStack, VStack } from '@telescope/cassini-ui';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { useContext } from 'react';
import { useClosed } from '../providers';
import { TiChevronRight, TiChevronLeft } from 'react-icons/ti';
import parse from 'html-react-parser';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';

export const ClosedOptionDetail = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.vote_modal });
  const { data: closedCopy } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.closed });
  const { language } = useContext(DataContext);

  const { activeCategory, activeVoteOption: voteOption } = useClosed();
  const navigate = useNavigate();

  if (!voteOption) {
    return null;
  }

  return (
    <>
      <Flex
        direction={{ base: 'column-reverse', lg: 'row' }}
        gap={{ base: 5, lg: 16 }}
        alignItems={{ base: 'center', lg: 'revert' }}
        textAlign={{ base: 'center', lg: 'left' }}
      >
        <Flex className="VoteOptionDetail__details-container" direction="column" w="100%">
          <VStack
            m="auto"
            mb={20}
            spacing={3}
            w={{ base: '85%', md: '100%' }}
            textAlign={{ base: 'center', lg: 'left' }}
            align={{ base: 'center', lg: 'flex-start' }}
          >
            <Text className="VoteOptionDetail__category-name" color="secondary.500" size="small" fontWeight={500}>
              {parse(activeCategory[`name_${language}`])}
            </Text>

            <Heading className="VoteOptionDetail__heading" as="h1" size="medium" textAlign="initial">
              {parse(voteOption[`name_${language}`])}
            </Heading>

            <Text
              className="VoteOptionDetail__secondary-name"
              size="small"
              fontWeight={500}
              _empty={{ display: 'none' }}
            >
              {parse(voteOption[`additional_name_${language}`])}
            </Text>

            <Text size="small" fontWeight={'500'}>
              {parse(voteOption[`studio_name_${language}`])}
            </Text>

            {voteOption[`description_${language}`] && (
              <Text size="medium">{parse(voteOption[`description_${language}`])}</Text>
            )}

            {voteOption[`copyright_${language}`] && (
              <Text size="extraSmall" color="secondary.700">
                {parse(voteOption[`copyright_${language}`])}
              </Text>
            )}

            {data.watch_button_text[`watch_button_text_${language}`] && data.where_to_watch_urls[language] && (
              <Link
                href={data.where_to_watch_urls[language]}
                key={activeCategory.id}
                target="_blank"
                color="primary.500"
                display="inline-flex"
                alignItems="center"
                fontWeight={700}
              >
                {data.watch_button_text[`watch_button_text_${language}`]}
                {language === 'ar' ? <TiChevronLeft /> : <TiChevronRight />}
              </Link>
            )}
          </VStack>

          <Spacer />

          <Button
            className="VoteOptionDetail__back-button"
            variant="secondary"
            size={{ base: 'md', lg: 'sm' }}
            w={{ base: '100%', md: 'auto' }}
            maxW={{ base: '335px' }}
            mb={[2, 3]}
            mx={{ base: 'auto', lg: 0 }}
            alignSelf={{ base: 'stretch', lg: 'flex-start' }}
            onClick={() => navigate('..')}
          >
            {parse(closedCopy.modal_button_text[`modal_button_text_${language}`])}
          </Button>
        </Flex>
        <Box className="VoteOptionDetail__img-container" w="100%" maxW="470px">
          <Image className="VoteOptionDetail__img" src={voteOption.image} m="auto" w={{ base: '85%', md: '100%' }} />
        </Box>
      </Flex>
      <Text
        color="secondary.400"
        sx={{ a: { color: 'primary.500' } }}
        textAlign={{ base: 'center', lg: 'left' }}
        size="extraSmall"
        maxW="400px"
        mx={{ base: 'auto', lg: 0 }}
      >
        {parse(data.disclaimer[`disclaimer_${language}`])}
      </Text>
    </>
  );
};
