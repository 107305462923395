import { ComponentStyleConfig } from '@chakra-ui/react';

export const buttonTheme: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 0,
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    fontSize: 14,
    minH: '40px',
  },
  sizes: {
    sm: {
      px: 12,
    },
    md: {
      px: 14,
    },
  },
  variants: {
    primary: {
      color: '#FFFFFF',
      backgroundColor: '#000000',
    },
    secondary: {
      color: '#000000',
      backgroundColor: 'primary.500',
      _disabled: {
        pointerEvents: 'none',
      },
    },
    tertiary: {
      color: '#FFFFFF',
      backgroundColor: 'transparent',
      border: '2px solid #FFFFFF',
    },
    link: {
      color: 'primary.500',
      backgroundColor: 'transparent',
      _hover: {
        textDecoration: 'none',
      },
    },
  },
};
