import { useContext, useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { Grid, GridItem, Image, Box, Flex, Spinner, AspectRatio, Button, VStack } from '@telescope/cassini-ui';
import { DataContext, useVoteHistory } from 'providers';
import { useVotingGrid } from 'features';
import { isEmpty } from 'lodash-es';
import { ReactSVG } from 'react-svg';
import backgroundImage from '../assets/social-graphic-bg.jpeg';
import logoImage from '../assets/social-graphic-logo.png';

const GRAPHIC = {
  WIDTH: 1060,
  HEIGHT: 728,
};

const GRID = {
  COLUMNS: 9,
  ROWS: 4,
};

const TILE = {
  WIDTH: 79,
  HEIGHT: 120,
};

interface ISocialGraphicProps {
  displayButton: boolean;
  displayCta: boolean;
  buttonCopy: string;
  ctaCopy: string;
}

export const SocialGraphic = (props: ISocialGraphicProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const [isLoading, setIsLoading] = useState(true);

  const {
    voteHistory: { history },
  } = useVoteHistory();
  const { allowedCategories } = useVotingGrid();
  const { language } = useContext(DataContext);

  const data = Object.entries(history).map(([key, value]) => {
    const category = allowedCategories.find(cat => cat.id === key);

    if (!category) return null;

    const voteOption = Object.values(category.voteOptions).find(option => option.id === value);

    return {
      copy: category![`name_${language}`],
      image: voteOption!.thumbnail,
      icon: category!.icon,
    };
  });

  const htmlToCanvas = () => {
    html2canvas(divRef.current!, {
      allowTaint: false,
      useCORS: true,
    }).then(canvas => {
      const dataURL = canvas.toDataURL('image/jpeg', 1.0);
      imageRef.current!.src = dataURL;
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const request = window.requestAnimationFrame(() => {
      htmlToCanvas();
    });
    return () => window.cancelAnimationFrame(request);
  }, []);

  if (isEmpty(history)) return null;

  const downloadGraphic = (imageUrl: string | undefined) => {
    if (!imageUrl) return;

    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'craa_social_graphic';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {isLoading && (
        <AspectRatio ratio={1.5}>
          <Box bg="rgba(0,0,0,0.5)">
            <Spinner />
          </Box>
        </AspectRatio>
      )}

      <VStack mb={4}>
        {imageRef.current?.src && props.displayCta && (
          <a href={imageRef.current.src} download="craa_social_graphic" style={{ fontWeight: 700 }}>
            {props.ctaCopy}
          </a>
        )}

        {imageRef.current?.src && props.displayButton && (
          <Button variant="secondary" size="sm" onClick={() => downloadGraphic(imageRef.current?.src)}>
            {props.buttonCopy}
          </Button>
        )}
      </VStack>

      <Image src="" ref={imageRef} maxW="100%" />

      <Box
        w={GRAPHIC.WIDTH}
        h={GRAPHIC.HEIGHT}
        ref={divRef}
        p="25px 15px"
        bg="black"
        bgImage={backgroundImage}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="102%"
        position="absolute"
        top={-100000}
        left={-1000000}
        dir="ltr"
      >
        <Image src={logoImage} w="550px" ml="auto" mb="15px" crossOrigin="anonymous" />

        <Grid
          templateColumns={`repeat(${GRID.COLUMNS}, ${TILE.WIDTH + 24}px)`}
          templateRows={`repeat(${GRID.ROWS}, 120px)`}
          gap="20px 14px"
          w="100%"
        >
          {data
            .filter(item => item)
            .map((item, i) => {
              return (
                <GridItem key={item!.copy} position="relative" as={Flex} direction="row">
                  <Image
                    src={item!.image}
                    alt=""
                    w={`${TILE.WIDTH}px`}
                    height={`${TILE.HEIGHT}px`}
                    crossOrigin="anonymous"
                  />
                  <Grid
                    position="absolute"
                    fontSize={9}
                    w="120px"
                    h="24px"
                    transform="rotate(270deg) translate(50px, 31px)"
                    color="#fff"
                    lineHeight={1.3}
                    bottom={0}
                    gridTemplateColumns="14px 1fr"
                    alignItems="center"
                    gap="2px"
                  >
                    <Box as="span" width="14px" height="14px">
                      <ReactSVG
                        src={item!.icon}
                        beforeInjection={svg => {
                          svg.setAttribute('style', 'width: 14px');
                          svg.setAttribute('style', 'height: 14px');
                        }}
                        afterInjection={() => {
                          if (i === data.length - 1) {
                            htmlToCanvas();
                          }
                        }}
                      />
                    </Box>
                    <Box as="span" px="4px" transform="translateY(-5px)" textAlign="left">
                      {item!.copy}
                    </Box>
                  </Grid>
                </GridItem>
              );
            })}
        </Grid>
      </Box>
    </>
  );
};
