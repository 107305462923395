import React, { createContext, useContext } from 'react';
import { useWidget as useCassiniWidget, UseWidgetResponseDTO } from '@telescope/cassini-hooks';
import { WIDGET_STATE_OPTIONS } from 'utils/constants';
import { UseQueryResult } from '@tanstack/react-query';
import { getWidgetId, getSidString } from 'utils';

const wid = getWidgetId();
const sidString = getSidString();

// TODO: update "any" typings
const ConnectWidgetContext = createContext<any>(undefined);

export const ConnectWidgetProvider = ({ children }: { children: React.ReactNode }) => {
  return <ConnectWidgetContext.Provider value={useCassiniWidget}>{children}</ConnectWidgetContext.Provider>;
};

export function useWidget(options: any): UseQueryResult<any, any> {
  const context = useContext(ConnectWidgetContext);

  if (context === undefined) {
    throw new Error('useWidget must be used within a ConnectWidgetProvider');
  }

  return context({
    widgetStateOptions: { ...WIDGET_STATE_OPTIONS, apiHash: wid + sidString },
    ...options,
  });
}

export type UseWidgetResponse = UseWidgetResponseDTO;
