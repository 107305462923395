import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { createContext, ReactElement, useContext, useMemo } from 'react';
import { chain, keyBy } from 'lodash-es';
import { CAT_WID, CONTESTANTS_WID, slugify, WIDGET_STATE_OPTIONS } from 'utils';
import { isTruthy } from '@telescope/cassini-utilities';
import { useMatch } from 'react-router-dom';
import { CLOSED_ROUTES } from '../routes';
import { ICategory, IVoteOption } from 'types';

interface ClosedGridContextProps {
  categories: { [catId: string]: ICategory };
  activeCategory: ICategory;
  activeVoteOption: IVoteOption;
  specialCategories: any[];
  isCategoryAllowed: (category: ICategory) => boolean;
}

export const ClosedContext = createContext<ClosedGridContextProps | null>(null);

export const ClosedProvider = ({ children }: { children: React.ReactNode }): ReactElement<string, string> => {
  const { language, languageCode } = useContext(DataContext);
  const { data: categories } = useWidget({
    widgetStateOptions: { ...WIDGET_STATE_OPTIONS, apiHash: CAT_WID },
    select: (data: UseWidgetResponse) => keyBy(data.snapshot.data, 'id'),
  });

  const { data: contestants } = useWidget({
    widgetStateOptions: { ...WIDGET_STATE_OPTIONS, apiHash: CONTESTANTS_WID },
    select: (data: UseWidgetResponse) => data.snapshot.data,
  });

  const { categorySlug = '' } = useMatch(`${CLOSED_ROUTES.GRID}*`)?.params || {};
  const { optionSlug = '' } = useMatch(`${CLOSED_ROUTES.GRID}/${CLOSED_ROUTES.OPTION}`)?.params || {};

  const value = useMemo(() => {
    return chain(contestants)
      .groupBy(x => x.catId)
      .map((value, key) => {
        value.sort((a, b) => a[`name_${language}`].localeCompare(b[`name_${language}`], languageCode));
        const voteOptions = keyBy(value, contestant => slugify(contestant.name));
        return { voteOptions, ...categories[key] };
      })
      .filter(cat => cat.name_en && !isTruthy(cat.special))
      .keyBy(cat => slugify(cat.name_en))
      .value();
  }, [categories, contestants, language, languageCode]);

  const specialCategories = useMemo(
    () => Object.values(categories).filter((cat: any) => isTruthy(cat.special)),
    [categories],
  );

  const activeCategory = useMemo(() => value[categorySlug], [categorySlug, value]);
  const activeVoteOption = useMemo(() => activeCategory?.voteOptions[optionSlug], [activeCategory, optionSlug]);

  const isCategoryAllowed = (category: ICategory) => {
    const allowedLanguages = category?.languages?.split(',').map((lang: string) => lang.trim());
    return allowedLanguages?.includes(language);
  };

  const provider = {
    categories: value,
    specialCategories,
    activeCategory,
    activeVoteOption,
    isCategoryAllowed,
  };

  return <ClosedContext.Provider value={provider}>{children}</ClosedContext.Provider>;
};

export function useClosed() {
  const ctx = useContext(ClosedContext);

  if (!ctx) {
    throw new Error('useClosed hook was called outside of context, wrap your component with ClosedProvider component');
  }

  return ctx;
}
