import { useCallback, useContext } from 'react';
import { DataContext, useWidget, UseWidgetResponse, LanguageOption } from 'providers';
import { Menu, MenuButton, MenuList, Button, MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';
import { AiOutlineCaretDown } from 'react-icons/ai';

interface LanguagesMenuProps {
  onLanguageChange?: (lang: LanguageOption) => void;
}

export const LanguagesMenu = ({ onLanguageChange }: LanguagesMenuProps) => {
  const { data: languages } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.language_support });
  const { language, changeLanguage } = useContext(DataContext);

  const getLanguageCode = useCallback(
    (id: string) => {
      const currentLang = languages.find((lang: LanguageOption) => lang.id === id);
      return currentLang.label;
    },
    [languages],
  );

  const handleLanguageChange = (lang: LanguageOption) => {
    changeLanguage(lang);
    onLanguageChange?.(lang);
  };

  return (
    <Menu>
      <MenuButton as={Button} leftIcon={<AiOutlineCaretDown color="#FFFFFF" />} variant="unstyled" fontSize="14px">
        {getLanguageCode(language) || 'Select Language'}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup type="radio">
          {languages.map((lang: LanguageOption) => (
            <MenuItemOption value={lang.id} key={lang.id} onClick={() => handleLanguageChange(lang)}>
              {lang.label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
