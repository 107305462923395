import deepmerge from "deepmerge";

export const generateTailorConfig = (opts = {}) => {
  const defaultOptions = {
    actions: [
      {
        type: "transform_image",
        inputs: [{ type: "upload", reference: "1" }],
        output_details: [
          {
            identifier: "processed",
            format: "JPEG",
            size: { type: "MAX_AREA", width: 1920, height: 1080 },
          },
        ],
      },
      {
        type: "flex",
        overrides: [
          {
            name: "image",
            inputs: [{ type: "action_result", reference: "processed" }],
          },
        ],
      },
      {
        type: "save_to_s3",
        inputs: [
          {
            type: "action_result",
            reference: "processed",
            destination_folder: "uploads",
          },
        ],
      },
    ],
    flex: {
      id: "",
      topics: 0,
      ruleset_collection_ids: "",
      user_name: "-",
      screen_name: "",
      message: "-",
      timestamp: "",
      image: "",
      video: "",
      avatar: "",
      user_score: "",
      country: "",
      state: "",
      account_age: "",
      user_lang: "",
      status_lang: "",
      profile_description: "",
      user_timezone: "",
      custom_parameters: {},
    },
  };

  return deepmerge(defaultOptions, opts);
};
