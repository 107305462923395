import { createContext, useContext, useState, useRef, useLayoutEffect, useEffect, useCallback, useMemo } from 'react';
import { useEvent } from 'react-use';

export interface EqualHeightProviderProps {
  children: React.ReactNode;
}

export interface EqualHeightContextProps {
  equalHeight: number;
  register: (key: string, ref: any) => void;
}

const EqualHeightContext = createContext<EqualHeightContextProps | null>(null);

export function EqualHeightProvider({ children }: EqualHeightProviderProps): JSX.Element {
  const [equalHeight, setEqualHeight] = useState<number>(0);
  const refs = useRef<Record<string, any>>({});

  const register = (key: string, ref: any) => {
    if (!key || !ref) return;
    refs.current[key] = ref;
  };

  const handleUpdate = useCallback(() => {
    const heights = Object.values(refs.current).map(node => node.getBoundingClientRect().height);
    const maxHeight = Math.max(...heights);
    setEqualHeight(maxHeight);
  }, [refs.current]);

  useLayoutEffect(() => {
    handleUpdate();
  }, [Object.values(refs.current)]);

  useEvent('resize', handleUpdate);

  return (
    <EqualHeightContext.Provider
      value={{
        equalHeight,
        register,
      }}
    >
      {children}
    </EqualHeightContext.Provider>
  );
}

export function useEqualHeight() {
  const ctx = useContext(EqualHeightContext);

  if (!ctx) {
    throw new Error(
      'useEqualHeight hook was called outside of context, wrap your component with EqualHeightProvider component',
    );
  }

  return ctx;
}
