import { useContext } from 'react';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { ButtonGroup, IconButton, Stack, Text } from '@telescope/cassini-ui';
import { AiFillTwitterSquare, AiFillFacebook } from 'react-icons/ai';
import { replaceWildcardsWithProperties } from '../utils';
import { IVoteOption, ICategory } from 'types';

function removeTrailingSlash(str: string) {
  return str.replace(/\/+$/, '');
}

export const Share = ({ voteOption, category }: { voteOption: IVoteOption; category: ICategory }) => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.share });
  const { language } = useContext(DataContext);

  const twitterShare = () => {
    let copy = (data.twitter[`copy_${language}`] || '')
      .replace(/{{CATEGORY}}/gi, category[`name_${language}`] || '')
      .replace(/{{CATEGORY_HASHTAG}}/gi, category[`hashtag_${language}`] || '');
    copy = replaceWildcardsWithProperties(copy, voteOption, language).trim();

    const url = new URL('https://twitter.com/intent/tweet');
    url.searchParams.append('text', copy);
    if (data.twitter.link) url.searchParams.append('url', data.twitter.link);

    window.open(url, '_blank', 'height=420,width=550');
  };

  const facebookShare = () => {
    const url = new URL(data.facebook.url);
    const pathname = removeTrailingSlash(url.pathname);
    url.pathname = pathname + `/share/${voteOption.id}/${category.id}/${language}`;

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url.toString())}`,
      'popup',
      'width=600, height=400, scrollbars=no',
    );
  };

  return (
    <Stack direction={{ base: 'column', md: 'row' }} alignItems="center" spacing={{ base: 1, md: 5 }}>
      {data.cta[`cta_${language}`] && (
        <Text fontSize="xl" fontWeight={700}>
          {data.cta[`cta_${language}`]}
        </Text>
      )}

      <ButtonGroup variant="unstyled" gap={2}>
        <IconButton
          data-share-method="twitter"
          className="ShareButton"
          aria-label="Share on Twitter"
          minW="auto"
          icon={<AiFillTwitterSquare size={30} onClick={twitterShare} />}
        />
        <IconButton
          data-share-method="facebook"
          className="ShareButton"
          aria-label="Share on Facebook"
          minW="auto"
          icon={<AiFillFacebook size={30} onClick={facebookShare} />}
        />
      </ButtonGroup>
    </Stack>
  );
};

export default Share;
