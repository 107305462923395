export const replaceWildcardsWithProperties = (str: string, object: Record<string, any>, language: string = 'en') => {
  const wildcards = str.match(/{{(.*?)}}/gi);

  if (!wildcards) return str;

  wildcards.forEach(wildcard => {
    const property = wildcard.replace('{{', '').replace('}}', '').toLocaleLowerCase();
    const copy = object[`${property}_${language}`] || object[property] || '';

    str = str.replace(wildcard, copy.trim());
  });

  return str;
};
