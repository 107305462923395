import { useDisclosure, useVote, VoteAPIResponseCode } from '@telescope/cassini-hooks';
import { Box, CloseButton, Flex, Grid, GridItem, Heading, IconButton, Text, Tooltip } from '@telescope/cassini-ui';
import { isTruthy } from '@telescope/cassini-utilities';
import { useAuth, VideoPlayer, VoteOptionCard } from 'features';
import { CategoryNavigation, useVotingGrid } from 'features/voting-app';
import parse from 'html-react-parser';
import { DataContext, useVoteHistory, useWidget, UseWidgetResponse } from 'providers';
import { useCallback, useContext, useEffect } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { ErrorType } from 'types';
import { ROUTES } from 'utils';

export const VoteOptionsGrid = () => {
  const { isOpen: isTooltipOpen, onOpen, onToggle, onClose } = useDisclosure();
  const { language } = useContext(DataContext);
  const { voteHistory, updateVoteHistory } = useVoteHistory();
  const { data: grid } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.grid });

  const { isAuthenticated, user } = useAuth();
  const { categorySlug } = useParams();
  const { categories, isCategoryAllowed } = useVotingGrid();

  const navigate = useNavigate();
  const vote = useVote();

  const category = categories[categorySlug!];
  const isAllowed = isCategoryAllowed(category);

  const fetchVoteHistory = useCallback(async () => {
    if (!category || !isAuthenticated() || !isAllowed) return;

    try {
      const { response_code, votestring } = await vote({
        method: user?.user.method,
        user_id: user?.user.user_id,
        action_type: 'get',
      });

      if (response_code === VoteAPIResponseCode.VALID && votestring) {
        updateVoteHistory(JSON.parse(votestring));
      }
    } catch {
      console.error('Vote History call failed.');
    }
    // adding vote to dependency array causes multiple re-renders (need fix in useVote hook)
  }, [user]);

  useEffect(() => {
    fetchVoteHistory();
  }, [fetchVoteHistory]);

  const refCallback = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      node.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'start' });
    }
  }, []);

  if (category && !isAllowed) {
    return <Navigate to={ROUTES.ERROR} state={{ errorType: ErrorType.LANGUAGE }} />;
  }

  if (!category) {
    return <Navigate to=".." />;
  }

  const displayRemainingVotes = voteHistory && isAuthenticated() && grid.settings.display_vote_count;

  const renderRemainingVotes = () => {
    if (!voteHistory) return null;

    const total = voteHistory.history?.total || 0;

    const maxVotes = grid.category_vote_limit;
    const remainingVotes = maxVotes - total < 0 ? 0 : maxVotes - total;

    const copyKey = remainingVotes === 1 ? 'singular' : 'plural';
    const countCopy = `${grid}.vote_count_label.${copyKey}.label_${language}`;

    return (
      <Text as="p" key={category.id}>
        <Text as="span" key={`count-${category.id}`}>
          {remainingVotes.toString()}
        </Text>
        <Text> {countCopy} </Text>
      </Text>
    );
  };

  return (
    <Box p={['0px', '0px', '13px']} py={'5px'} ref={refCallback} key={categorySlug}>
      <Box as="section" w="100%" bg="black" p={[3, 5, 8]} borderRadius={{ base: 2, md: 5 }}>
        <Flex justify="flex-end">
          <CloseButton size="lg" onClick={() => navigate('..')} />
        </Flex>
        <Box textAlign="center">
          <Box
            aria-live="polite"
            aria-atomic="true"
            role="region"
            pb={[3, 5]}
            display="inline-flex"
            alignItems="center"
          >
            <Heading size={'small'} mr={2}>
              {parse(category[`name_${language}`])}
            </Heading>

            {category[`description_${language}`] && (
              <Tooltip
                label={parse(category[`description_${language}`])}
                bg="#4A4E58"
                placement="top"
                isOpen={isTooltipOpen}
              >
                <IconButton
                  variant="unstyled"
                  minW="none"
                  minHeight="auto"
                  height="auto"
                  aria-label="description"
                  onMouseEnter={onOpen}
                  onMouseLeave={onClose}
                  onClick={onToggle}
                  icon={<AiOutlineInfoCircle />}
                />
              </Tooltip>
            )}
          </Box>
          {category[`video_url_${language}`] && (
            <VideoPlayer title={category[`name_${language}`]} src={category[`video_url_${language}`]} />
          )}

          {(grid.additional_info[`additional_info_${language}`] || displayRemainingVotes) && (
            <Box>
              {grid.additional_info[`additional_info_${language}`] && (
                <Text as="p">
                  {parse(grid.additional_info[`additional_info_${language}`])}

                  {displayRemainingVotes && <Text as="span"> | </Text>}
                </Text>
              )}

              {isTruthy(displayRemainingVotes) && renderRemainingVotes()}
            </Box>
          )}
        </Box>

        <Grid templateColumns={{ base: '1fr', md: 'repeat(2, minmax(0, 1fr))' }} gap={[4, 4, 10]} justifyItems="center">
          {Object.values(category.voteOptions).map(voteOption => (
            <GridItem key={voteOption.id} w="100%" minW={0}>
              <VoteOptionCard voteOption={voteOption} />
            </GridItem>
          ))}
        </Grid>

        <CategoryNavigation />

        <Outlet />
      </Box>
    </Box>
  );
};
