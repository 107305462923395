import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConnectWidgetProvider, DataProvider } from 'providers';
import { App, UserRole } from 'features';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({ defaultOptions: { queries: { useErrorBoundary: true, suspense: true } } });

let basename = window.USER_ROLE === UserRole.JUDGE ? '/animeawards/vote_judges' : '/animeawards/vote';

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConnectWidgetProvider>
        <DataProvider>
          <BrowserRouter basename={basename}>
            <App />
          </BrowserRouter>
        </DataProvider>
      </ConnectWidgetProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
