import { VoteAPIResponseCode } from '@telescope/cassini-hooks';
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Input,
  Text,
  VStack,
} from '@telescope/cassini-ui';
import { useAuth } from 'features/auth';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { FormEvent, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ErrorType } from 'types';
import { ROUTES } from 'utils';
import parse from 'html-react-parser';

type JudgeLoginFormValues = {
  code: string;
  optin_1: boolean;
  optin_2: boolean;
};

export const JudgeLogin = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.login });
  const { language } = useContext(DataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    setError,
  } = useForm<JudgeLoginFormValues>({ defaultValues: { code: '' } });

  const renderCheckboxes = () => {
    return data.optins.map((optin: any) => {
      if (!optin || !optin.name) return null;

      const label = optin.copy[`copy_${language}`] || '';
      const isRequired = optin.required === 'true';
      const errorMessage = isRequired ? optin.error_message[`error_message_${language}`] : false;
      const name = optin.name as keyof JudgeLoginFormValues;

      return (
        <Controller
          name={optin.name}
          key={optin.name}
          control={control}
          rules={{ required: errorMessage }}
          defaultValue={false}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <>
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                  checked={value}
                  ref={ref}
                  sx={{ '.chakra-checkbox__control': { borderColor: 'secondary.400', backgroundColor: '#192E38' } }}
                >
                  <Text sx={{ a: { color: 'primary.500' } }} color="secondary.700">
                    {parse(label)}
                  </Text>
                </Checkbox>
                {errors[name] && (
                  <Text color="red" size="small">
                    {errors[name]?.message}
                  </Text>
                )}
              </>
            );
          }}
        />
      );
    });
  };

  const { login } = useAuth();

  const submitLogin = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(async (formData: JudgeLoginFormValues) => {
      try {
        setIsSubmitting(true);
        formData.code = formData.code.trim();
        const user = await login({ ...formData });

        if (user) {
          setIsSubmitting(false);
          navigate(-1);
        }
      } catch (error) {
        setIsSubmitting(false);
        if (error === VoteAPIResponseCode.WINDOW) {
          return navigate(ROUTES.ERROR, { state: { errorType: ErrorType.WINDOW } });
        }
        return setError('code', {
          type: 'invalid',
          message: data.judge_error_message[`judge_error_message_${language}`],
        });
      }
    })();
  };

  return (
    <form id="loginForm" onSubmit={submitLogin}>
      <VStack spacing={7} p={['40px 16px', 10]} maxW="700px">
        <Heading size="medium">{data.judge_headline[`judge_headline_${language}`]}</Heading>
        <FormControl w={'full'} isInvalid={!!errors.code} sx={{ width: 'initial', marginTop: '10px !important' }}>
          <Controller
            name="code"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  {!!errors.code && (
                    <FormErrorMessage justifyContent="center" mb={2}>
                      {errors.code.message as string}
                    </FormErrorMessage>
                  )}
                  <Input
                    w="full"
                    name="code"
                    placeholder={data.judge_placeholder[`judge_placeholder_${language}`]}
                    onChange={onChange}
                    value={value}
                    maxLength={20}
                    variant="flushed"
                    textAlign="left"
                    fontWeight="900"
                    textTransform="uppercase"
                    letterSpacing="2px"
                    display="block"
                    mb={6}
                    mx="auto"
                    maxW="450px"
                    _placeholder={{
                      fontWeight: 400,
                      textTransform: 'initial',
                      letterSpacing: 'normal',
                      color: '#FFFFFF',
                    }}
                  />
                </>
              );
            }}
          />
          <VStack spacing={4} alignItems="left">
            {renderCheckboxes()}
          </VStack>
        </FormControl>
        <Button
          variant="secondary"
          size={['sm', 'md']}
          px={14}
          isLoading={isSubmitting}
          form="loginForm"
          type="submit"
          disabled={!isDirty || isSubmitting}
          style={{ marginTop: 20 }}
        >
          {data.button_text[`button_text_${language}`]}
        </Button>
      </VStack>
    </form>
  );
};
