import { HTMLChakraProps, chakra, forwardRef, SystemStyleObject } from '@chakra-ui/system';

export interface TimeStatNumberProps extends HTMLChakraProps<'dd'> {}

export const TimeStatValue = forwardRef<TimeStatNumberProps, 'dd'>(function TimeStatValue(props, ref) {
    // TODO: update styles object with cms styles
    const valueStyles: SystemStyleObject = {
        textAlign: 'center',
        fontWeight: 'bold'
    };

    return (
        <chakra.dd
            ref={ref}
            {...props}
            __css={{
                fontFeatureSettings: 'pnum',
                fontVariantNumeric: 'proportional-nums',
                ...valueStyles
            }}
        />
    );
});

TimeStatValue.displayName = 'TimeStatValue';