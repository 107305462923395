import { useRoutes, useLocation } from 'react-router-dom';
import { Auth } from 'features';
import { useWidget, UseWidgetResponse } from 'providers';
import { WindowStatus } from 'types';
import { ROUTES } from 'utils';

export const GlobalModalRoutes = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot });
  const isWindowOpen = data.settings.window_status === WindowStatus.OPEN;

  const location = useLocation();
  const backgroundLocation = location.state?.backgroundLocation;

  const globalModalRoutes = [{ path: ROUTES.AUTH, element: <Auth /> }];

  const element = useRoutes(backgroundLocation ? [...globalModalRoutes, { path: '*' }] : []);

  return isWindowOpen ? element : null;
};
