import { Box, Container, Divider, Flex, Grid, GridItem, IconButton, Link, Text, Image } from '@telescope/cassini-ui';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import React, { useContext, useEffect } from 'react';
import { GenericObject } from 'types';
import { LanguagesMenu } from './LanguagesMenu';
import { iconMap } from './utils';
import { getTextDirection } from 'utils';

export const Footer = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.footer });
  const { columns = {}, copyright } = data;
  const { language, languageCode } = useContext(DataContext);

  useEffect(() => {
    window.evidon?.notice?.activateTranslations(languageCode.toLowerCase());
  }, [languageCode]);

  useEffect(() => {
    document.documentElement.setAttribute('lang', language);

    const direction = getTextDirection(language);
    document.documentElement.setAttribute('dir', direction);
  }, [language]);

  const getLink = (link: GenericObject) => {
    switch (link.type) {
      case 'cookie_consent_tool':
        return (
          <Link
            as="button"
            pb={2}
            fontSize={14}
            onClick={() => window.evidon.notice.showPreferencesDialog()}
            variant={'link'}
          >
            {link.copy[`copy_${language}`]}
          </Link>
        );
      case 'icon_link':
        return (
          <>
            <IconButton
              aria-label={link.copy[`copy_${language}`]}
              variant="link"
              display="contents"
              color="#FFFFFF"
              sx={{ svg: { display: 'initial', marginRight: '5px' } }}
              icon={React.createElement(iconMap[link.icon])}
            />
            <Link href={link[`link_${language}`]} target="_blank" pb={2} fontSize={14}>
              {link.copy[`copy_${language}`]}
            </Link>
          </>
        );
      case 'link':
      default:
        if (!link.copy[`copy_${language}`]) return null;
        return (
          <Link href={link.link} target="_blank" pb={2} fontSize={14}>
            {link.copy[`copy_${language}`]}

            {link.icon && (
              <Image src={link.icon} maxH="30px" maxW="30px" display="inline-block" mx="6px" verticalAlign="middle" />
            )}
          </Link>
        );
    }
  };

  const getColumns = () => {
    return Object.keys(columns).map((key: string, index: number) => {
      return (
        <GridItem key={key} mb={{ base: 5, md: 0 }} w="full">
          <Grid gridTemplateColumns={{ base: 'repeat(2, 1fr)', md: '1fr' }}>
            <Box>
              <Text key={index} fontWeight={700} fontSize={[14, 16]} color="#FFFFFF">
                {columns[key].title[`title_${language}`]}
              </Text>
            </Box>
            <Flex direction="column">
              {columns[key].links.map((link: GenericObject, index: number) => {
                return <Box key={index}>{getLink(link)}</Box>;
              })}
            </Flex>
          </Grid>
        </GridItem>
      );
    });
  };

  return (
    <Box as="footer" bg="footer" color="on-footer" w="full">
      <Container p={[3, 3, 5, 12]} maxW="1250px">
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gridGap={{ base: 0, md: 12 }}
          justifyItems={{ base: 'flex-start', md: 'center' }}
        >
          {getColumns()}
        </Grid>
        <Divider variant="divider" border="1px" width="initial" />
        <Flex justifyContent="space-between" align="center" px={{ base: 5, md: 10 }}>
          <Text size="small">{copyright[`copyright_${language}`]}</Text>
          <LanguagesMenu />
        </Flex>
      </Container>
    </Box>
  );
};
