import { Button, HStack, Link, Box, Text } from '@telescope/cassini-ui';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateList } from 'hooks';
import { useVotingGrid } from 'features/voting-app';
import { isTruthy } from '@telescope/cassini-utilities';

export const CategoryNavigation = () => {
  const { language } = useContext(DataContext);
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.categories_navigation });
  const { categories } = useVotingGrid();

  const { categorySlug } = useParams();

  const categoriesSlugs = Object.keys(categories);
  const { state: activeCategorySlug, prev, next } = useStateList(categoriesSlugs, { initialState: categorySlug });

  useEffect(() => {
    navigate(`../${activeCategorySlug}`);
  }, [activeCategorySlug]);

  const navigate = useNavigate();

  if (!isTruthy(data.settings.display)) return null;

  return (
    <Box>
      <HStack justifyContent={'space-between'}>
        <Button aria-label="Navigate to previous category" onClick={prev}>
          <FaArrowLeft />
          {data.previous_category_label[`label_${language}`]}
        </Button>
        <Button aria-label="Navigate to all categories list" onClick={() => navigate('..')}>
          {data.all_categories_label[`label_${language}`]}
        </Button>
        <Button aria-label="Navigate to next category" onClick={next}>
          {data.next_category_label[`label_${language}`]}
          <FaArrowRight />
        </Button>
      </HStack>
    </Box>
  );
};
