import { AppProvider, useWidget, UseWidgetResponse } from 'providers';
import { useEffect } from 'react';
import { AppRoutes } from 'routes';
import { insertFonts, STYLES_WID, WIDGET_STATE_OPTIONS } from 'utils';

export const App = () => {
  const { data: styles } = useWidget({
    widgetStateOptions: { ...WIDGET_STATE_OPTIONS, apiHash: STYLES_WID },
    select: (data: UseWidgetResponse) => data.snapshot.text,
  });

  useEffect(() => {
    if (styles?.global.font_import) {
      insertFonts(styles?.global.font_import);
    }
  }, [styles?.global.font_import]);

  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
};
