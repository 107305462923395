import { createContext, useState, ReactElement } from 'react';
import { GenericObject } from 'types';
import { STYLES_WID, getLanguageSuffix, WIDGET_STATE_OPTIONS } from 'utils';
import { useWidget, UseWidgetResponse } from './ConnectProvider';

export type LanguageOption = {
  label: string;
  code: string;
  id: string;
};

const defaultLanguage = getLanguageSuffix(navigator.language);

export const DataContext = createContext<GenericObject>({ language: defaultLanguage, styles: {} });

export const DataProvider = ({ children }: { children: React.ReactNode }): ReactElement<string, string> => {
  const [language, setLanguage] = useState<string>(defaultLanguage);
  const [languageCode, setLanguageCode] = useState<string>(defaultLanguage);

  const { data: styles } = useWidget({
    widgetStateOptions: { ...WIDGET_STATE_OPTIONS, apiHash: STYLES_WID },
    select: (data: UseWidgetResponse) => data.snapshot.text,
  });

  const provider = {
    language,
    languageCode,
    changeLanguage: (newLang: LanguageOption) => {
      setLanguage(newLang.id);
      setLanguageCode(newLang.code);
    },
    styles,
  };

  return <DataContext.Provider value={provider}>{children}</DataContext.Provider>;
};
