import { Outlet, Route, Routes } from 'react-router-dom';
import { Error, VoteModal, VoteOptionsGrid } from 'features';
import { VotingGridProvider } from '../providers';
import { CategoriesGrid } from '../components/CategoriesGrid';
import { ROUTES } from 'utils';

export const VOTING_ROUTES = {
  VOTE_OPTION: ':voteOptionSlug',
  VOTING_GRID: ':categorySlug',
};

export const VotingAppRoutes = () => {
  return (
    <VotingGridProvider>
      <Outlet />
      <Routes>
        <Route path="/" element={<CategoriesGrid />}>
          <Route path={VOTING_ROUTES.VOTING_GRID} element={<VoteOptionsGrid />}>
            <Route path={VOTING_ROUTES.VOTE_OPTION} element={<VoteModal />} />
          </Route>
          <Route path={ROUTES.ERROR} element={<Error />} />
        </Route>
      </Routes>
    </VotingGridProvider>
  );
};
