import { Show, Hide, Box } from '@telescope/cassini-ui';
import { NavMenu } from './NavMenu';
import { NavBanner } from './NavBanner';

export const Navigation = () => {
  return (
    <Box as="nav" w="100%">
      <Hide above="md">
        <NavMenu />
      </Hide>

      <Show above="md">
        <NavBanner />
      </Show>
    </Box>
  );
};
