import React, { createContext, useCallback, useContext, useState } from 'react';

import { GenericObject } from '@telescope/cassini-utilities';
import TailorApiService from '@telescope/tailor-js';
import { useMemo } from 'react';
import { TailorJobStatus } from '../types/tailor';
import { generateTailorConfig } from '../utils/generateTailorConfig';

interface TailorProviderProps {
  children: React.ReactNode;
  tailorId: string;
  rulesetCollectionId: string;
  topicId: string;
  env?: 'dev' | 'test' | 'prod';
}
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

interface TailorContext {
  uploadImage(jobId: string, file: File): Promise<string>;
  createJob(config: GenericObject): string;
  isUploading: boolean;
}

const TailorContext = createContext<any>(undefined);

export const TailorProvider = ({
  rulesetCollectionId,
  tailorId,
  env = 'prod',
  topicId,
  children,
}: TailorProviderProps) => {
  const tailor = useMemo(() => new TailorApiService(tailorId, env), [tailorId, env]);
  const [isUploading, setIsUploading] = useState(false);

  const onUploadProgress = useCallback((params: { loaded: number; total: number }) => {
    const { loaded, total } = params;
    const percent = Math.floor((loaded * 100) / total);
    console.log(`${loaded}b of ${total}b | ${percent}% transferred`);
  }, []);

  /**
   * Checks if the given job is finished processing. If so, returns the output url,
   * otherwise it keeps polling until it finishes or an error is thrown
   * @param jobId jobID to get the image URL of
   */
  const pollUploadStatus = useCallback(
    async (jobId: string): Promise<string> => {
      const { status, actions } = (await tailor.getJob(jobId)).data;

      if (status === TailorJobStatus.ERROR) {
        throw new Error('File could not be uploaded');
      }
      if (status === TailorJobStatus.FINISHED) {
        return TailorJobStatus.FINISHED;
      }

      // Try again after a delay
      await delay(1000);
      return await pollUploadStatus(jobId);
    },
    [tailor],
  );

  const createJob = useCallback(
    async (flexParams: GenericObject): Promise<string> => {
      const { job_id } = (
        await tailor.createJob(
          generateTailorConfig({
            flex: {
              topics: topicId,
              ruleset_collection_ids: rulesetCollectionId,
              ...flexParams,
            },
          }),
        )
      ).data;
      return job_id;
    },
    [rulesetCollectionId, tailor, topicId],
  );

  const uploadImage = useCallback(
    async (jobId: string, file: File): Promise<string> => {
      if (!jobId) {
        throw new Error('You must create a job before uploading an Image.');
      }

      setIsUploading(true);
      try {
        await tailor.uploadImage(jobId, file, '1', onUploadProgress);
        await tailor.startJob(jobId);
        const status = await pollUploadStatus(jobId);
        return status;
      } catch (err) {
        console.error('Failed to upload', err);
        return Promise.reject(err);
      } finally {
        setIsUploading(false);
      }
    },
    [tailor, onUploadProgress, pollUploadStatus],
  );

  const value = {
    uploadImage,
    createJob,
    isUploading,
  };

  return <TailorContext.Provider value={value}>{children}</TailorContext.Provider>;
};

export function useTailor(): TailorContext {
  const context = useContext(TailorContext);

  if (context === undefined) {
    throw new Error('useTailor must be used within a TailorProvider');
  }

  return context;
}
