import { Box, Flex, Text, HStack } from '@telescope/cassini-ui';
import parse from 'html-react-parser';
import { DataContext } from 'providers';
import { useContext } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useClosed, useEqualHeight } from 'features';
import { slugify } from 'utils';
import { isTruthy } from '@telescope/cassini-utilities';
import { ICategory } from 'types';
import { ReactSVG } from 'react-svg';

export const ClosedCategoryButton = ({ category, isPreVote }: { category: ICategory; isPreVote: boolean }) => {
  const { language } = useContext(DataContext);
  const { isCategoryAllowed } = useClosed();

  const { categorySlug } = useParams();

  const slug = slugify(category.name_en);
  const isSpecial = isTruthy(category.special);

  const { equalHeight, register } = useEqualHeight();

  if (slug === categorySlug || !isCategoryAllowed(category)) return null;

  return (
    <Flex w={['100%', '100%', '50%', '25%']} px={[4, 3]} py={2}>
      <Box
        as={!isSpecial && !isPreVote ? RouterLink : Box}
        to={slug}
        fontSize={13}
        w="100%"
        borderRadius={{ base: 2, md: 6 }}
        textAlign="initial"
        bg="#000"
        p={3}
        minH={{ base: '65px', md: '75px', lg: '85px' }}
        fontWeight="700"
        textTransform="uppercase"
        className="CategoryButton"
        data-category-name={category[`name_${language}`]}
        _hover={!isSpecial && !isPreVote ? { boxShadow: '0px 0px 4px 3px #FFFFFF40' } : {}}
      >
        <Flex align="center" h="100%" minH={`${equalHeight}px`}>
          <HStack h="auto" spacing={3} ref={el => register(category.name_en, el)}>
            <Box borderRadius="50%" w="40px" h="40px" bg="#fff" flexShrink={0}>
              <ReactSVG src={category.icon} />
            </Box>
            <Text>{parse(category[`name_${language}`])}</Text>
          </HStack>
        </Flex>
      </Box>
    </Flex>
  );
};
