import { ComponentStyleConfig } from '@chakra-ui/react';

export const modalTheme: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      my: 0,
      minH: {
        base: '100vh',
        lg: 'auto',
      },
      maxH: '100vh',
    },
    closeButton: {
      svg: {
        width: 20,
      },
    },
  },
  sizes: {
    md: {
      dialog: {
        width: '100vw',
        maxWidth: '1142px',
      },
    },
  },
};
