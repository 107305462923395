import { Box, Flex, HStack } from '@telescope/cassini-ui';
import { AuthNav } from 'features';
import { useWidget, UseWidgetResponse } from 'providers';
import { GenericObject } from 'types';
import { NavLink } from './NavLink';

export const NavBanner = () => {
  const { data: links } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.navigation });

  return (
    <HStack as={Flex} w="100%" p={[2, 5]} bg="#000" spacing="60px" justifyContent="center">
      {links.map((link: GenericObject) => (
        <NavLink data={link} key={link.link} />
      ))}
    </HStack>
  );
};
