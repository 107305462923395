import React, { createContext, useContext, useState } from 'react';
import { GenericObject } from 'types';

interface VoteHistoryContextProps {
  voteHistory: VoteState;
  updateVoteHistory: (history: VoteState) => void;
  resetVoteHistory: () => void;
}
export interface VoteState {
  history: { total?: number } & GenericObject;
}

const initialState = {
  voteHistory: {
    history: {},
  },
  updateVoteHistory: () => null,
  resetVoteHistory: () => null,
};

export const VoteHistoryContext = createContext<VoteHistoryContextProps>(initialState);

export const VoteHistoryProvider = ({ children }: { children: React.ReactNode }) => {
  const [voteHistory, setVoteHistory] = useState({ history: {} });

  const provider = {
    voteHistory,
    updateVoteHistory: (newHistory: VoteState) => {
      setVoteHistory({ history: newHistory });
    },
    resetVoteHistory: () => {
      setVoteHistory({ history: {} });
    },
  };

  return <VoteHistoryContext.Provider value={provider}>{children}</VoteHistoryContext.Provider>;
};

export const useVoteHistory = () => {
  const context = useContext(VoteHistoryContext);

  if (!context) {
    throw new Error('useVoteHistory hook was called out of context. Wrap your component with VoteHistoryProvider.');
  }

  return context;
};
