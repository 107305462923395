import { useContext } from 'react';
import { Box, Flex, Heading, Spacer, Text, AspectRatio, Link } from '@telescope/cassini-ui';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { slugify } from 'utils';
import parse from 'html-react-parser';
import { Link as RouterLink } from 'react-router-dom';
import { GoChevronRight, GoChevronLeft } from 'react-icons/go';

export const ClosedOptionCard = ({ option }: { option: any }) => {
  const { language } = useContext(DataContext);
  const { data } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.text.closed,
  });

  return (
    <Link
      as={RouterLink}
      to={slugify(option.name)}
      aria-label={`info for ${option[`name_${language}`]}`}
      _hover={{ textDecoration: 'none' }}
      _focusVisible={{ boxShadow: 'none' }}
      className="VoteModal__vote-btn"
      data-vote-option-name={option[`name_${language}`]}
      data-group
    >
      <Flex _groupHover={{ bg: '#18173A' }} _groupFocus={{ bg: '#18173A' }} p={1} pr={2} gridGap={3}>
        <AspectRatio w="100%" maxW="80px" flexShrink={0} ratio={2 / 3}>
          <LazyLoadImage
            crossOrigin="anonymous"
            alt=""
            src={
              option.thumbnail ||
              'https://ts-cms-production.votenow.tv/campaign/10/11/1011537/16684629076372b93bcfe1a0.82498371.jpeg'
            }
          />
        </AspectRatio>

        <Flex direction="column" w="100%" minW={0}>
          <Box>
            <Heading size="extraSmall" color="white" textAlign="initial" mb={1}>
              {parse(option[`name_${language}`])}
            </Heading>

            {option.studio_name && (
              <Text size="small" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                {parse(option[`studio_name_${language}`])}
              </Text>
            )}
          </Box>

          <Spacer />

          <Flex
            align={'center'}
            gap={1}
            _groupHover={{ color: '#fff' }}
            _groupFocus={{ color: '#fff' }}
            color="primary.500"
            alignSelf="flex-end"
          >
            <Text mb="2px" fontWeight="700" size="small">
              {parse(data.option_button_text[`option_button_text_${language}`])}
            </Text>

            {language === 'ar' ? <GoChevronLeft /> : <GoChevronRight />}
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};
