import { HTMLChakraProps, chakra, forwardRef, SystemStyleObject } from '@chakra-ui/system';

export interface TimeStatLabelProps extends HTMLChakraProps<'dt'> {}

export const TimeStatLabel = forwardRef<TimeStatLabelProps, 'dt'>(function TimeStatLabel(props, ref) {
    // TODO: update styles object with cms styles
    const labelStyles: SystemStyleObject = {
        textAlign: 'center',
        fontWeight: 'bold'
    };

    return (
        <chakra.dt ref={ref} {...props} __css={{...labelStyles}} />
    );
});

TimeStatLabel.displayName = 'TimeStatLabel';