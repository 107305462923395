import { Box, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@telescope/cassini-ui';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useVotingGrid } from '../providers';
import { Thanks, VoteOptionDetail, useAuth } from 'features';
import { useStateList } from 'hooks';

export const VoteModal: FC = () => {
  const { activeCategory: category } = useVotingGrid();

  const navigate = useNavigate();
  const { voteOptionSlug } = useParams();

  const { isAuthenticated } = useAuth();

  const voteOptionSlugs = Object.keys(category?.voteOptions || []);
  const { prev, next, state: activeOptionSlug } = useStateList(voteOptionSlugs, { initialState: voteOptionSlug });
  const [bgImage, setBgImage] = useState({
    mobile: 'https://ts-cms-production.votenow.tv/campaign/10/11/1011537/1670522950639228467bbc95.68708260.jpg',
    desktop: 'https://ts-cms-production.votenow.tv/campaign/10/11/1011537/16705229466392284281b247.07720795.jpg',
  });

  useEffect(() => {
    navigate(`../${activeOptionSlug}`);
  }, [activeOptionSlug, navigate]);

  const onVoteSuccess = useCallback(() => {
    setBgImage({
      mobile: 'https://ts-cms-production.votenow.tv/campaign/10/11/1011537/16705229416392283d7c2cc8.33305194.jpg',
      desktop: 'https://ts-cms-production.votenow.tv/campaign/10/11/1011537/167052293663922838ad5854.96973493.jpg',
    });
    setComponent(<Thanks />);
  }, [isAuthenticated, navigate]);

  const [component, setComponent] = useState<React.ReactNode>(
    <VoteOptionDetail onVoteSuccess={onVoteSuccess} onPrevious={prev} onNext={next} />,
  );

  return (
    <Modal isOpen={true} onClose={() => navigate('..')} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="lg" />
        <ModalBody
          bgImage={{
            base: bgImage.mobile,
            lg: bgImage.desktop,
          }}
          bgSize="cover"
          bgRepeat="no-repeat"
          color="on-surface"
          p={{ base: '40px 10px', lg: '60px 40px 30px' }}
        >
          <Box data-vote-option-name={''}>{component}</Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
