import { Box, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@telescope/cassini-ui';
import { useNavigate } from 'react-router-dom';
import { ClosedOptionDetail } from './ClosedOptionDetail';

export const ClosedModal = () => {
  const navigate = useNavigate();

  return (
    <Modal isOpen={true} onClose={() => navigate('..')} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="lg" />
        <ModalBody bg="surface" color="on-surface">
          <Box data-vote-option-name={''} mt={[10, '80px']}>
            <ClosedOptionDetail />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
